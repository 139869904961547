import React from 'react';
import { Component } from 'react';
import '../config';

export default class Army extends Component {
  constructor(props) {
    super(props);
    //Set default message
    this.state = {
      antiSpam: false,
      army: '',
      count: 0,
      soldierColorState: 'blue',
      tankColorState: 'blue',
      boatColorState: 'blue',
      planeColorState: 'blue',
      soldier_units: 1,
      tank_units: 1,
      boat_units: 1,
      plane_units: 1,
    };
  }

  componentDidMount() {
    this.fetchArmy();
  }

  componentWillUnmount = () => {
    if (this.makeErrorDisappear) clearTimeout(this.makeErrorDisappear);
    if (this.resetSpam) clearTimeout(this.resetSpam);
    if (this.decreaseCounter) clearTimeout(this.decreaseCounter);
  };

  fetchArmy = async () => {
    const url = '/api/army/get';
    const data = await fetch(url);
    const army = await data.json();
    //console.log(army);
    this.setState({
      army: army,
    });
  };

  buyArmy(troop, units) {
    // Afin d'éviter le spam des boutons
    if (this.state.count === 0) {
      this.setState({
        count: 2,
      });
      this.decreaseCounter = setTimeout(() => {
        this.setState({
          count: 0,
        });
      }, 2000);

      fetch('/api/army/buy', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ troop: troop, units: units }),
      }).then((res) => {
        if (res.status === 200) {
          let audio = new Audio(require('../../src/assets/sounds/army.mp3'));
          audio.volume = global.config.sound.volume;
          audio.play();
          this.fetchArmy();
          // On peut réupdate directement après un update
          this.setState({
            count: 0,
          });
        } else {
          let audio = new Audio(require('../../src/assets/sounds/error.mp3'));
          audio.volume = global.config.sound.volume;
          audio.play();
          switch (res.statusText) {
            case 'Soldier':
              this.setState({
                soldierColorState: 'red',
              });
              break;
            case 'Tank':
              this.setState({
                tankColorState: 'red',
              });
              break;
            case 'Boat':
              this.setState({
                boatColorState: 'red',
              });
              break;
            case 'Plane':
              this.setState({
                planeColorState: 'red',
              });
              break;
            default:
              break;
          }
          this.makeErrorDisappear = setTimeout(() => {
            this.setState({
              soldierColorState: 'blue',
              tankColorState: 'blue',
              boatColorState: 'blue',
              planeColorState: 'blue',
            });
          }, 2000);
        }
      });
    } else {
      console.log('Spam Alert');
    }
  }

  // Format displaying numbers
  formatNumber(number) {
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      const new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  // Prevent from entering text to input
  block(input) {
    input.preventDefault();
  }

  updateInputColorLeave = (event) => {
    const { value, name } = event.target; // voir si virer value

    switch (name) {
      case 'soldier':
        if (this.state.soldierColorState === 'green') {
          this.setState({
            soldierColorState: 'blue',
          });
        } else if (this.state.soldierColorState === 'red') {
          this.setState({
            soldierColorState: 'blue',
          });
        }
        break;
      case 'tank':
        if (this.state.tankColorState === 'green') {
          this.setState({
            tankColorState: 'blue',
          });
        } else if (this.state.tankColorState === 'red') {
          this.setState({
            tankColorState: 'blue',
          });
        }
        break;
      case 'boat':
        if (this.state.boatColorState === 'green') {
          this.setState({
            boatColorState: 'blue',
          });
        } else if (this.state.boatColorState === 'red') {
          this.setState({
            boatColorState: 'blue',
          });
        }
        break;
      case 'plane':
        if (this.state.planeColorState === 'green') {
          this.setState({
            planeColorState: 'blue',
          });
        } else if (this.state.planeColorState === 'red') {
          this.setState({
            planeColorState: 'blue',
          });
        }
        break;

      default:
        break;
    }
  };

  // Updating input color when hovering button
  updateInputColorEnter = (event) => {
    const { value, name } = event.target; // voir si virer value

    switch (name) {
      case 'soldier':
        this.setState({
          soldierColorState: 'green',
        });
        break;
      case 'tank':
        this.setState({
          tankColorState: 'green',
        });
        break;
      case 'boat':
        this.setState({
          boatColorState: 'green',
        });
        break;
      case 'plane':
        this.setState({
          planeColorState: 'green',
        });
        break;
      default:
        break;
    }
  };

  unequipWeapon(weapon) {
    fetch('/api/army/unequip-weapon', {
      method: 'POST',
      body: JSON.stringify({ weapon: weapon }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.fetchArmy();
        } else if (res.statusText) {
          console.log(res.statusText);
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  equipWeapon(weapon) {
    if (!this.state.antiSpam) {
      this.setState({
        antiSpam: true,
      });
      fetch('/api/army/equip-weapon', {
        method: 'POST',
        body: JSON.stringify({ weapon: weapon }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.fetchArmy();
          } else if (res.statusText) {
            console.log(res.statusText);
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
      this.resetSpam = setTimeout(() => {
        this.setState({
          antiSpam: false,
        });
      }, 1000);
    } else {
      console.log('Stop Spam');
    }
  }

  // Updating values states on change happening in input
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  toLowerCase(string) {
    if (string === 'RPG') {
      return string.toLowerCase();
    }
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  render() {
    var self = this;
    var weapons;
    if (this.state.army.weapons) {
      weapons = this.state.army.weapons.map((weapon) => (
        <li key={weapon.name}>
          <span className="weapon-quantity">{weapon.quantity}</span>
          <img
            onClick={() => this.equipWeapon(weapon.name)}
            src={require(`../assets/images/army/weapons/${this.toLowerCase(weapon.name)}.png`)}
            alt="Weapon"
          />
        </li>
      ));
    }

    var soldier_weapons;
    if (this.state.army.equipped_weapons) {
      soldier_weapons = this.state.army.equipped_weapons.map((weapon) => {
        if (
          weapon.name === 'Knife' ||
          weapon.name === 'Gun' ||
          weapon.name === 'Rifle' ||
          weapon.name === 'RPG' ||
          weapon.name === 'Sniper'
        ) {
          return (
            <li key={weapon.name}>
              <img
                onClick={() => self.unequipWeapon(weapon.name)}
                src={require(`../assets/images/army/weapons/mini/${self.toLowerCase(weapon.name)}.png`)}
                alt="Weapon"
              />
              <span className="equipped-weapon-quantity">{weapon.equipped}</span>
            </li>
          );
        } else {
          return console.log();
        }
      });
    }

    var tank_weapons;
    if (this.state.army.equipped_weapons) {
      tank_weapons = this.state.army.equipped_weapons.map((weapon) => {
        if (weapon.name === 'Missile' && weapon.equipped.tank > 0) {
          return (
            <li key="missile-tank">
              <img
                style={{ paddingTop: '4px' }}
                onClick={() => self.unequipWeapon('tank')}
                src={require(`../assets/images/army/weapons/missile.png`)}
                alt="Weapon"
              />
              <span style={{ paddingBottom: '2px' }} className="equipped-weapon-quantity">
                {weapon.equipped.tank}
              </span>
            </li>
          );
        } else {
          return console.log();
        }
      });
    }

    var boat_weapons;
    if (this.state.army.equipped_weapons) {
      boat_weapons = this.state.army.equipped_weapons.map((weapon) => {
        if (weapon.name === 'Missile' && weapon.equipped.boat > 0) {
          return (
            <li key="missile-boat">
              <img
                style={{ paddingTop: '4px' }}
                onClick={() => self.unequipWeapon('boat')}
                src={require(`../assets/images/army/weapons/missile.png`)}
                alt="Weapon"
              />
              <span style={{ paddingBottom: '2px' }} className="equipped-weapon-quantity">
                {weapon.equipped.boat}
              </span>
            </li>
          );
        } else {
          return console.log();
        }
      });
    }

    var plane_weapons;
    if (this.state.army.equipped_weapons) {
      plane_weapons = this.state.army.equipped_weapons.map((weapon) => {
        if (weapon.name === 'Missile' && weapon.equipped.plane > 0) {
          return (
            <li key="missile-plane">
              <img
                style={{ paddingTop: '4px' }}
                onClick={() => self.unequipWeapon('plane')}
                src={require(`../assets/images/army/weapons/missile.png`)}
                alt="Weapon"
              />
              <span style={{ paddingBottom: '2px' }} className="equipped-weapon-quantity">
                {weapon.equipped.plane}
              </span>
            </li>
          );
        } else {
          return console.log();
        }
      });
    }

    return (
      <>
        {this.state.army ? (
          <>
            <div id="title-army">
              <h1>
                <span role="img" aria-label="Emoji Army">
                  🏋🏼
                </span>{' '}
                Army{' '}
                <span id="attack-army">
                  <img src={require(`../assets/images/army/title-army/sword.png`)} alt="Sword" />
                  <p>{this.formatNumber(this.state.army.attack)}</p>
                </span>
                <span id="defense-army">
                  <p>{this.formatNumber(this.state.army.defense)}</p>{' '}
                  <img src={require(`../assets/images/army/title-army/shield.png`)} alt="Shield" />
                </span>
              </h1>
            </div>

            {this.state.army.weapons.length >= 1 ? (
              <div id="army-weapons" className="animate__animated animate__fadeIn">
                <ul>{weapons}</ul>
              </div>
            ) : (
              console.log()
            )}

            <div className="army-component animate__animated animate__fadeIn">
              <div className="single-army-component soldier">
                <h4>Soldier</h4>
                <p className="lvl"> [Units: {this.formatNumber(this.state.army.soldier.units)}]</p>

                {this.state.army.equipped_weapons ? <ul>{soldier_weapons}</ul> : console.log()}

                <img src={require(`../assets/images/army/units/soldier.png`)} alt="Soldier" />
                <section className="attack-defense">
                  <p>
                    <img src={require(`../assets/images/army/smaller/sword.png`)} alt="Sword" />{' '}
                    {this.formatNumber(this.state.army.soldier.attack * this.state.soldier_units)}{' '}
                    <img src={require(`../assets/images/army/smaller/shield.png`)} alt="Shield" />{' '}
                    {this.formatNumber(this.state.army.soldier.defense * this.state.soldier_units)}
                  </p>
                </section>
                <button
                  className={this.state.soldierColorState}
                  name="soldier"
                  onClick={() => this.buyArmy('soldier', this.state.soldier_units)}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">BUY</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/gold-bars.png`)}
                      alt="Gold Icon"
                    />
                    <p>{this.formatNumber(this.state.army.soldier.price.gold * this.state.soldier_units)}</p>
                  </span>
                </button>
                <input
                  name="soldier_units"
                  type="number"
                  min="1"
                  max="100"
                  defaultValue={this.state.soldier_units}
                  className={this.state.soldierColorState}
                  onChange={this.handleInputChange}
                  onKeyPress={this.block.bind(this)}
                />
              </div>

              <div className="single-army-component tank">
                <h4>Tank</h4>
                <p className="lvl"> [Units: {this.formatNumber(this.state.army.tank.units)}]</p>
                {this.state.army.weapons ? <ul>{tank_weapons}</ul> : console.log()}
                <img src={require(`../assets/images/army/units/tank.png`)} alt="Tank" />
                <section className="attack-defense">
                  <p>
                    <img src={require(`../assets/images/army/smaller/sword.png`)} alt="Sword" />{' '}
                    {this.formatNumber(this.state.army.tank.attack * this.state.tank_units)}{' '}
                    <img src={require(`../assets/images/army/smaller/shield.png`)} alt="Shield" />{' '}
                    {this.formatNumber(this.state.army.tank.defense * this.state.tank_units)}
                  </p>
                </section>
                <button
                  className={this.state.tankColorState}
                  name="tank"
                  onClick={() => this.buyArmy('tank', this.state.tank_units)}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">BUY</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/iron-bars.png`)}
                      alt="Iron Icon"
                    />
                    <p>{this.formatNumber(this.state.army.tank.price.iron * this.state.tank_units)}</p>
                  </span>
                  <span className="second-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/oil-baril.png`)}
                      alt="Oil Icon"
                    />
                    <p>{this.formatNumber(this.state.army.tank.price.oil * this.state.tank_units)}</p>
                  </span>
                </button>
                <input
                  name="tank_units"
                  type="number"
                  min="1"
                  max="100"
                  defaultValue={this.state.tank_units}
                  className={this.state.tankColorState}
                  onChange={this.handleInputChange}
                  onKeyPress={this.block.bind(this)}
                />
              </div>

              <div className="single-army-component boat">
                <h4>Boat</h4>
                <p className="lvl"> [Units: {this.formatNumber(this.state.army.boat.units)}]</p>
                {this.state.army.weapons ? <ul>{boat_weapons}</ul> : console.log()}
                <img src={require(`../assets/images/army/units/boat.png`)} alt="Boat" />
                <section className="attack-defense">
                  <p>
                    <img src={require(`../assets/images/army/smaller/sword.png`)} alt="Sword" />{' '}
                    {this.formatNumber(this.state.army.boat.attack * this.state.boat_units)}{' '}
                    <img src={require(`../assets/images/army/smaller/shield.png`)} alt="Shield" />{' '}
                    {this.formatNumber(this.state.army.boat.defense * this.state.boat_units)}
                  </p>
                </section>
                <button
                  className={this.state.boatColorState}
                  name="boat"
                  onClick={() => this.buyArmy('boat', this.state.boat_units)}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">BUY</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/iron-bars.png`)}
                      alt="Iron Icon"
                    />
                    <p>{this.formatNumber(this.state.army.boat.price.iron * this.state.boat_units)}</p>
                  </span>
                  <span className="second-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/oil-baril.png`)}
                      alt="Oil Icon"
                    />
                    <p>{this.formatNumber(this.state.army.boat.price.oil * this.state.boat_units)}</p>
                  </span>
                </button>
                <input
                  name="boat_units"
                  type="number"
                  min="1"
                  max="100"
                  defaultValue={this.state.boat_units}
                  className={this.state.boatColorState}
                  onChange={this.handleInputChange}
                  onKeyPress={this.block.bind(this)}
                />
              </div>

              <div className="single-army-component plane">
                <h4>Plane</h4>
                <p className="lvl"> [Units: {this.formatNumber(this.state.army.plane.units)}]</p>
                {this.state.army.weapons ? <ul>{plane_weapons}</ul> : console.log()}
                <img src={require(`../assets/images/army/units/plane.png`)} alt="Plane" />
                <section className="attack-defense">
                  <p>
                    <img src={require(`../assets/images/army/smaller/sword.png`)} alt="Sword" />{' '}
                    {this.formatNumber(this.state.army.plane.attack * this.state.plane_units)}{' '}
                    <img src={require(`../assets/images/army/smaller/shield.png`)} alt="Shield" />{' '}
                    {this.formatNumber(this.state.army.plane.defense * this.state.plane_units)}
                  </p>
                </section>
                <button
                  className={this.state.planeColorState}
                  name="plane"
                  onClick={() => this.buyArmy('plane', this.state.plane_units)}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">BUY</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/iron-bars.png`)}
                      alt="Iron Icon"
                    />
                    <p>{this.formatNumber(this.state.army.plane.price.iron * this.state.plane_units)}</p>
                  </span>
                  <span className="second-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/oil-baril.png`)}
                      alt="Oil Icon"
                    />
                    <p>{this.formatNumber(this.state.army.plane.price.oil * this.state.plane_units)}</p>
                  </span>
                </button>
                <input
                  name="plane_units"
                  type="number"
                  min="1"
                  max="100"
                  defaultValue={this.state.plane_units}
                  className={this.state.planeColorState}
                  onChange={this.handleInputChange}
                  onKeyPress={this.block.bind(this)}
                />
              </div>
            </div>
          </>
        ) : (
          <span></span>
        )}
      </>
    );
  }
}
