import React, { Component } from 'react';

export default class Horses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    console.log('Horses coming!');
  }

  render() {
    return <>Horses coming</>;
  }
}