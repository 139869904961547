import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Connexion from './HomeComponents/Connexion';
import Nav from './GameComponents/Nav';
import Inscription from './HomeComponents/Inscription';
import Logout from './GameComponents/Logout';
import ForgotPass from './HomeComponents/ForgotPass';
import Rules from './HomeComponents/Rules';
import About from './HomeComponents/About';
import Credits from './HomeComponents/Credits';

import Board from './GameComponents/Board';
import Market from './GameComponents/Market';
import Horses from './GameComponents/Horses';
import Profil from './GameComponents/Profil';
import UpdateProfil from './GameComponents/UpdateProfil';
import MessageInbox from './GameComponents/MessageInbox';
import MessageWrite from './GameComponents/MessageWrite';
import MessageViewer from './GameComponents/MessageViewer';
import Population from './GameComponents/Population';
import Lands from './GameComponents/Lands';
import Technologies from './GameComponents/Technologies';
import Buildings from './GameComponents/Buildings';
import Army from './GameComponents/Army';
import Chat from './GameComponents/Chat';
import Forum from './GameComponents/Forum';
import Help from './GameComponents/Help';
import ForumCreate from './GameComponents/ForumCreate';
import ForumTopic from './GameComponents/ForumTopic';
import ChatConsole from './GameComponents/ChatConsole';
import ErrorPage from './ErrorPage';

import withAuth from './withAuth';
import withActiveAuth from './withActiveAuth';
import withoutAuth from './withoutAuth';
import withModAuth from './withModAuth';
import RessourcesBar from './GameComponents/RessourcesBar';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={HomeContainer} />
          <Route path="/connexion" exact component={HomeContainer} />
          <Route path="/inscription" exact component={HomeContainer} />
          <Route path="/forgot_pass" exact component={HomeContainer} />
          <Route path="/forgot_pass/activate/:id" exact component={HomeContainer} />
          <Route path="/rules" exact component={HomeContainer} />
          <Route path="/about" exact component={HomeContainer} />
          <Route path="/credits" exact component={HomeContainer} />

          <Route path="/board" exact component={GameContainer} />
          <Route path="/market" exact component={GameContainer} />
          <Route path="/horses" exact component={GameContainer} />
          <Route path="/profil" exact component={GameContainer} />
          <Route path="/profil/update" exact component={GameContainer} />
          <Route path="/profil/view/:otherMemberId" exact component={GameContainer} />
          <Route path="/inbox" exact component={GameContainer} />
          <Route path="/inbox/view/:id" exact component={GameContainer} />
          <Route path="/inbox/view/:id/:page" exact component={GameContainer} />
          <Route path="/inbox/sent" exact component={GameContainer} />
          <Route path="/inbox/write" exact component={GameContainer} />
          <Route path="/help" exact component={GameContainer} />
          <Route path="/forum" exact component={GameContainer} />
          <Route path="/forum/topic/:id" exact component={GameContainer} />
          <Route path="/forum/topic/:id/:page" exact component={GameContainer} />
          <Route path="/forum/create" exact component={GameContainer} />
          <Route path="/chat" exact component={GameContainer} />
          <Route path="/chat/console" exact component={GameContainer} />
          <Route path="/population" exact component={GameContainer} />
          <Route path="/lands" exact component={GameContainer} />
          <Route path="/technologies" exact component={GameContainer} />
          <Route path="/buildings" exact component={GameContainer} />
          <Route path="/army" exact component={GameContainer} />
          <Route path="/logout" exact component={GameContainer} />
          <Route path="/logout/:inactivity" exact component={GameContainer} />

          <Route component={ErrorPage} />
        </Switch>
      </div>
    </Router>
  );
}

const GameContainer = () => (
  <>
    <Nav />
    <RessourcesBar />
    <Route path="/board" exact component={withAuth(Board)} />
    <Route path="/market" exact component={withActiveAuth(Market)} />
    <Route path="/horses" exact component={withActiveAuth(Horses)} />
    <Route path="/profil" exact component={withActiveAuth(Profil)} />
    <Route path="/profil/update" exact component={withActiveAuth(UpdateProfil)} />
    <Route path="/profil/view/:otherMemberId" exact component={withActiveAuth(Profil)} />
    <Route path="/inbox" exact component={withActiveAuth(MessageInbox)} />
    <Route path="/inbox/view/:id" exact component={withActiveAuth(MessageViewer)} />
    <Route path="/inbox/view/:id/:page" exact component={withActiveAuth(MessageViewer)} />
    <Route path="/inbox/sent" exact component={withActiveAuth(MessageInbox)} />
    <Route path="/inbox/write" exact component={withActiveAuth(MessageWrite)} />
    <Route path="/help" exact component={withActiveAuth(Help)} />
    <Route path="/forum" exact component={withActiveAuth(Forum)} />
    <Route path="/forum/topic/:id" exact component={withActiveAuth(ForumTopic)} />
    <Route path="/forum/topic/:id/:page" exact component={withActiveAuth(ForumTopic)} />
    <Route path="/forum/create" exact component={withActiveAuth(ForumCreate)} />
    <Route path="/chat" exact component={withActiveAuth(Chat)} />
    <Route path="/chat/console" exact component={withModAuth(ChatConsole)} />
    <Route path="/population" exact component={withActiveAuth(Population)} />
    <Route path="/lands" exact component={withActiveAuth(Lands)} />

    <Route path="/technologies" exact component={withActiveAuth(Technologies)} />
    <Route path="/buildings" exact component={withActiveAuth(Buildings)} />
    <Route path="/army" exact component={withActiveAuth(Army)} />

    <Route path="/logout" exact component={withAuth(Logout)} />
    <Route path="/logout/:inactivity" exact component={withAuth(Logout)} />
  </>
);

const HomeContainer = () => (
  <>
    <Route path="/" exact component={withoutAuth(Connexion)} />
    <Route path="/connexion" exact component={withoutAuth(Connexion)} />
    <Route path="/inscription" exact component={withoutAuth(Inscription)} />
    <Route path="/forgot_pass" exact component={withoutAuth(ForgotPass)} />
    <Route path="/forgot_pass/activate/:id" exact component={withoutAuth(ForgotPass)} />
    <Route path="/rules" exact component={Rules} />
    <Route path="/about" exact component={About} />
    <Route path="/credits" exact component={Credits} />
  </>
);

export default App;
