import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="error-container">
          <img
            className="error-img"
            src={require('../src/assets/images/404.jpg')}
            alt="404 Not Found"
            draggable="false"
          />
          <figcaption className="error-figcaption">404 Not Found</figcaption>
          <p>
            <Link to='/board'>Go Back</Link>
          </p>
        </div>
      </>
    );
  }
}
