import React from 'react';
import { Component } from 'react';
import io from 'socket.io-client'; // used in chat-script.js
import { Link } from 'react-router-dom';

export default class Chat extends Component {
  constructor() {
    super();
    window.chatComponent = this;
    //Set default message
    this.state = {
      user_id: '',
      username: '',
      rang: '',
      connected_users: [],
    };
  }

  componentDidMount() {
    this.fetchSessionData();

    //socket.io.js
    this.sockets = document.createElement('script');
    this.sockets.src = '/socket.io/socket.io.js'; //http://localhost:4000/socket.io/socket.io.js
    this.sockets.setAttribute('defer', true);

    document.body.appendChild(this.sockets);
    //chat-script.js
    this.chat = document.createElement('script');
    this.chat.src = 'https://www.dokime.co/scripts/chat-script.js'; //http://localhost:4000/scripts/chat-script.js
    this.chat.setAttribute('defer', true);

    document.body.appendChild(this.chat);
  }

  componentWillUnmount() {
    console.log('Component Unmounted, byeChat!');
    this.updateLastVisit();
    window.disconnectFromChat();
    document.body.removeChild(this.chat);
    document.body.removeChild(this.sockets);
  }

  fetchSessionData = async () => {
    const url = '/api/chat/get-own-informations';
    const data = await fetch(url);
    const profil = await data.json();
    //console.log(profil);

    this.setState({
      user_id: profil.id,
      username: profil.username,
      rang: profil.rang,
    });

    window.pseudo = profil.username;
    window.id = profil.id;
    window.rang = this.getRang(profil.rang);
  };

  updateLastVisit() {
    fetch('/api/notifications/update-last-visit', {
      method: 'POST',
      body: JSON.stringify({ page: 'chat' }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log('Last Visit Page Updated!');
        } else if (res.statusText) {
          console.log(res.statusText);
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateUsers(data) {
    //console.log('React: updateUsers() call:', data.values());
    this.setState({
      connected_users: data,
    });
  }

  getRang(rank) {
    if (rank === 0) return 'lambda';
    if (rank === 1) return 'mod';
    if (rank === 2) return 'admin';
  }

  render() {
    return (
      <>
        <div className="chat-component">
          <h1>
            <span role="img" aria-label="crayon">
              ✏️
            </span>{' '}
            Welcome
          </h1>
          <div id="message-container"></div>
          <form id="form-container">
            <input type="text" id="message-chat-input" maxLength="50" autoComplete="none" required autoFocus />
            <button type="submit" id="send-chat-button">
              Envoyer
            </button>
          </form>
        </div>

        <div id="users-container">
          <p>Players on Chat</p>

          <ul>
            <li id="username">
              <Link to={`/profil/view/${this.state.user_id}`} className={this.getRang(this.state.rang)}>
                {this.state.username}
              </Link>
            </li>

            {this.state.connected_users.map((element) => {
              return (
                <li key={element.username}>
                  <Link to={`/profil/view/${element.id}`} className={element.rang}>
                    {element.username}
                  </Link>
                </li>
              );
            })}
          </ul>

          {this.state.rang > 0 ? (
            <Link id="history" to="/chat/console" title="Administration Console">
              <img src={require('../assets/images/chat/terminal.png')} alt="Administration Console" />
            </Link>
          ) : (
            <span></span>
          )}
        </div>
      </>
    );
  }
}
