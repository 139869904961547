import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class MessageInbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      page: 0,
    };
  }

  componentDidMount() {
    this.fetchMessages(this.state.page);
  }

  fetchMessages = async (page) => {
    const url = '/api/inbox/get/' + page;
    const data = await fetch(url);
    if (data.status === 200) {
      const messages = await data.json();
      console.log(messages);
      this.setState({
        messages: messages,
        page: page,
      });
    } else {
      console.log(data.statusText);
    }
  };

  loadMessagesNext = () => {
    if (this.state.messages.length >= 10) {
      const page_to_fetch = this.state.page + 1;
      this.fetchMessages(page_to_fetch);
    }
  };

  loadMessagesPrevious = () => {
    const page = this.state.page;
    if (page > 0) {
      this.fetchMessages(page - 1);
    }
  };

  getRang(rank) {
    if (rank === 0) return 'lambda';
    if (rank === 1) return 'mod';
    if (rank === 2) return 'admin';
  }

  render() {
    var self = this;
    return (
      <>
        <div id="arrow-title">
          {(function () {
            if (self.state.messages.length >= 1) {
              if (self.state.messages[0].hasMore) {
                return (
                  <button id="next-btn-inbox" onClick={self.loadMessagesNext}>
                    <span role="img" aria-label="Next">
                      ➡️
                    </span>
                  </button>
                );
              }
            }
          })()}

          {this.state.page > 0 ? (
            <button id="previous-btn-inbox" onClick={this.loadMessagesPrevious}>
              <span role="img" aria-label="Back">
                ⬅️
              </span>
            </button>
          ) : (
            <span></span>
          )}

          <div id="title-inbox">
            <h1>
              <span role="img" aria-label="Emoji Inbox">
                📪
              </span>{' '}
              Inbox
            </h1>
          </div>
        </div>

        <div className="inbox-component">
          <div className="line"></div>
          <table>
            <colgroup>
              <col width="280px" />
              <col />
              <col />
              <col />
            </colgroup>

            <thead>
              <tr>
                <th>Subject</th>
                <th>Expeditor</th>
                <th>Date</th>
              </tr>
            </thead>

            <tbody>
              {this.state.messages.map((element) => {
                const link = '/inbox/view/' + element._id;
                return (
                  <tr key={element._id} className={`message-state-${element.seen}`}>
                    <td className="message-link">
                      <Link to={link}>{element.subject}</Link>
                    </td>
                    <td className="expeditor">
                      <Link
                        to={`/profil/view/${element.expeditor_id}`}
                        className={this.getRang(element.expeditor_rang)}
                      >
                        {element.expeditor_username}
                      </Link>
                    </td>
                    <td className="date">{element.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {this.state.messages.length <= 0 ? <p className="no-message-loaded">Inbox Empty</p> : <span></span>}
        </div>
      </>
    );
  }
}
