import React from 'react';
import { Component } from 'react';

export default class RealEstate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      real_estate: this.props.real_estate,
      rent: this.props.rent,
      lands_bonus: this.props.lands_bonus,
      business_bonus: this.props.business_bonus,
      housesTypeOne: 0,
      housesTypeTwo: 0,
      housesTypeThree: 0,
    };
  }

  componentDidMount() {
    if (this.state.real_estate !== undefined) this.getHousesNumber();
  }

  getHousesNumber() {
    var housesTypeOne = 0;
    var housesTypeTwo = 0;
    var housesTypeThree = 0;

    this.state.real_estate.forEach((owned_house) => {
      switch (owned_house.house_type) {
        case 'house_1':
          housesTypeOne++;
          break;
        case 'house_2':
          housesTypeTwo++;
          break;
        case 'house_3':
          housesTypeThree++;
          break;
        default:
          break;
      }
    });
    this.setState({
      housesTypeOne: housesTypeOne,
      housesTypeTwo: housesTypeTwo,
      housesTypeThree: housesTypeThree
    });
  }

  getRent() {
    const business_bonus = this.state.business_bonus + 100;
    return this.formatNumber((this.state.rent*(business_bonus + this.state.lands_bonus))/100);
  }

  // Format displaying numbers
  formatNumber(number) {
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      const new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  render() {
    return (
      <div id="real-estate-owned-component">
        <h3>Real Estate Rents</h3>
        <p>{this.getRent()} <img src={require(`../../src/assets/images/market/gold-coins.png`)} alt="Gold Coins"/> / day</p>
        <span className="green-color">(+{this.formatNumber(this.state.business_bonus + this.state.lands_bonus)}%)</span>
        <ul>
          <li>
            <img src={require(`../../src/assets/images/market/real_estate/house_2.png`)} alt="House Type Two" />
            <p>{this.state.housesTypeTwo}</p>
          </li>
          <li>
            <img src={require(`../../src/assets/images/market/real_estate/house_1.png`)} alt="House Type One" />
            <p>{this.state.housesTypeOne}</p>
          </li>
          <li>
            <img src={require(`../../src/assets/images/market/real_estate/house_3.png`)} alt="House Type Three" />
            <p>{this.state.housesTypeThree}</p>
          </li>
        </ul>
      </div>
    );
  }
}
