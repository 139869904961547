import React from 'react';
import { Component } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/amchartsdark';

export default class BankTradeChart extends Component {
  timeintervalmarket;
  callOnce = true;
  callOnceMarketClock = true;
  constructor(props) {
    super(props);
    this.state = {
      isInvestingStockIndex: false,
      isBuying: false,
      isBuyingMarket: false,
      color_produce_button: '',
      color_create_button: '',
      minuts: 0,
      seconds: 0,
      investQuantity: 100,
      investPrice: 0,
      error: '',
      successInvest: '',
      errorInvest: '',
      errorMarket: '',
      investAction: 'BUY',
      bank: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    clearInterval(this.timeintervalmarket);
    clearTimeout(this.timeOut);
    clearTimeout(this.makeInvestMsgDisappear);
    if (this.chart) this.chart.dispose();
  }

  fetchData = async () => {
    const url = '/api/market/get-gold-ratio';
    const data = await fetch(url);
    if (data.status === 200) {
      var bank = await data.json();
      this.setState({
        bank: bank,
        investQuantity: 0,
        investPrice: 0,
      });
      if (!this.chart) this.initializeChart();
      else this.updateChartData();
      this.initializeMarketClock();
    } else {
      console.log(data.statusText);
    }
  };

  updateChartData() {
    let data = [];
    this.state.bank.gold_ratio.forEach((entry) => {
      let date = new Date(entry.date);
      let value = (100 - entry.ratio).toFixed(2);
      data.push({ date: date, value: value, lineColor: '#ff80ff' });
    });

    this.chart.data = [];
    this.chart.data = data;
    this.chart.dataProvider = data;
    this.chart.validateData();
  }

  initializeChart() {
    let chart = am4core.create('bankcharts', am4charts.XYChart);
    am4core.unuseAllThemes();
    am4core.useTheme(am4themes_dark);
    am4core.useTheme(am4themes_animated);

    let data = [];
    this.state.bank.gold_ratio.forEach((entry) => {
      let date = new Date(entry.date);
      let value = (100 - entry.ratio).toFixed(2);
      data.push({ date: date, value: value, lineColor: '#ff80ff' });
    });

    chart.data = data;

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;
    //let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.dateX = 'date';
    //series.tooltipText = '{value}';
    series.tooltipHTML = `<div id="stock-index-value">{value}</div>`;
    series.stroke = am4core.color('white');
    series.tooltip.pointerOrientation = 'vertical';

    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color('black');

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.snapToSeries = series;
    chart.cursor.xAxis = dateAxis;

    //chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarX = new am4core.Scrollbar();

    this.chart = chart;
  }

  initializeMarketClock = () => {
    var self = this;
    const index = this.state.bank.gold_ratio.length - 1;
    var previous_update = new Date(this.state.bank.gold_ratio[index].date); // la dernière update

    var deadline = new Date();
    deadline.setHours(previous_update.getHours());
    deadline.setMinutes(previous_update.getMinutes() + 2);
    deadline.setSeconds(previous_update.getSeconds());

    function updateClock() {
      const t = self.getTimeRemaining(deadline, previous_update);

      self.setState({
        minutsMarket: t.minuts, //.slice(-2),
        secondsMarket: t.seconds, //.slice(-2),
      });

      if (t.total <= 0) {
        clearInterval(self.timeintervalmarket);
        self.timeOut = setTimeout(() => {
          // self.chart.dispose();
          self.fetchData(self.state.page);
        }, 100); // au cas où le reset en backend prend un peu de temps
      }
    }

    if (self.callOnceMarketClock) {
      updateClock();
      self.callOnceMarketClock = false;
    }
    self.timeintervalmarket = setInterval(updateClock, 1000);
  };

  getTimeRemaining(endtime, startTime) {
    // startTime virer
    if (typeof startTime === Date) {
      const total = Date.parse(endtime) - Date.parse(startTime);
      const seconds = Math.floor((total / 1000) % 60);
      const minuts = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return {
        total,
        days,
        hours,
        minuts,
        seconds,
      };
    } else {
      const total = Date.parse(endtime) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minuts = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return {
        total,
        days,
        hours,
        minuts,
        seconds,
      };
    }
  }

  // Format displaying numbers
  formatNumber(number) {
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      const new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  handleWaterChange = (event) => {
    const { value, name } = event.target;
    const index = this.state.bank.gold_ratio.length - 1;
    const ratio = this.state.bank.gold_ratio[index].ratio;
    const maxPrice = Number((1000000 / ratio).toFixed(0));

    if (value > 1000000) {
      this.setState({
        [name]: 1000000,
        investPrice: maxPrice,
      });
    } else if (value < 0) {
      const price = Number((100 / ratio).toFixed(0));
      this.setState({
        [name]: 100,
        investPrice: price,
      });
    } else {
      const price = Number((value / ratio).toFixed(0));
      if (price > 50000) {
        this.setState({
          [name]: value,
          investPrice: 50000,
        });
      } else {
        this.setState({
          [name]: value,
          investPrice: price,
        });
      }
    }
  };

  handleGoldChange = (event) => {
    const { value, name } = event.target;
    const index = this.state.bank.gold_ratio.length - 1;
    const ratio = this.state.bank.gold_ratio[index].ratio;
    const maxPrice = Number((1000000 / ratio).toFixed(0));

    if (value > 50000) {
      this.setState({
        [name]: maxPrice,
        investQuantity: 1000000,
      });
    } else if (value >= maxPrice) {
      this.setState({
        [name]: maxPrice,
        investQuantity: 1000000,
      });
    } else if (value < 0) {
      const quantity = Number((1 * ratio).toFixed(0));
      this.setState({
        [name]: 1,
        investQuantity: quantity,
      });
    } else {
      const quantity = Number((value * ratio).toFixed(0));
      if (quantity > 1000000) {
        this.setState({
          [name]: value,
          investQuantity: 1000000,
        });
      } else {
        this.setState({
          [name]: value,
          investQuantity: quantity,
        });
      }
    }
  };

  updateInvestAction = (e) => {
    if (this.state.investAction === 'BUY') {
      this.setState({
        investAction: 'SELL',
      });
    } else {
      this.setState({
        investAction: 'BUY',
      });
    }
  };

  //   returnPrice(ratio) {
  //     const quantity = this.state.investQuantity;
  //     var price = (quantity / ratio).toFixed(0); // on vire les virgules
  //     this.setState({
  //       investPrice: price,
  //     });
  //     return price;
  //   }

  submitInvest = (e) => {
    e.preventDefault();
    if (!this.state.isInvestingStockIndex) {
      this.setState({
        isInvestingStockIndex: true,
      });
      fetch('/api/market/submit-buy-stock-index', {
        method: 'POST',
        body: JSON.stringify({
          invest_action: this.state.investAction,
          quantity: this.state.investQuantity,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log('Invest Done!');
            if (this.state.investAction === 'BUY') {
              let audio = new Audio(require('../../src/assets/sounds/buy.mp3'));
              audio.play();
            } else if (this.state.investAction === 'SELL') {
              let audio = new Audio(require('../../src/assets/sounds/sell.mp3'));
              audio.play();
            }
            document.getElementById('success-invest').style.opacity = '1';
            document.getElementById('dollar-trade').style.opacity = '0';
            this.setState({
              successInvest: 'Invest Done!',
            });
          } else if (res.statusText) {
            console.log(res.statusText);
            document.getElementById('error-invest').style.opacity = '1';
            document.getElementById('dollar-trade').style.opacity = '0';
            this.setState({
              errorInvest: res.statusText,
            });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });

      this.makeInvestMsgDisappear = setTimeout(() => {
        document.getElementById('success-invest').style.opacity = '0';
        document.getElementById('error-invest').style.opacity = '0';
        document.getElementById('dollar-trade').style.opacity = '1';
        this.setState({
          errorInvest: '',
          successInvest: '',
          isInvestingStockIndex: false,
        });
      }, 3000);
    } else {
      console.log('Stop Spam');
    }
  };

  render() {
    // var self = this;
    if (this.state.bank.gold_ratio !== undefined) {
      const index = this.state.bank.gold_ratio.length - 1;
      var ratio = this.state.bank.gold_ratio[index].ratio;
    }
    return (
      <div id="bank-div">
        <div id="bank-chart-actions">
          <img id="dollar-trade" src={require('../../src/assets/images/market/time-trade.png')} alt="Dollar Trade" />
          <span id="error-invest">{this.state.errorInvest}</span>
          <span id="success-invest">{this.state.successInvest}</span>
          <h3>{(100 - ratio).toFixed(2)}</h3>
          <div id="market-counter-clock">
            <div>
              <span className="left">{this.state.minutsMarket}</span>
            </div>
            <div id="two-dots">
              <span>:</span>
            </div>
            <div>
              <span className="right">{this.state.secondsMarket}</span>
            </div>
          </div>

          <form onSubmit={this.submitInvest}>
            <input
              name="investQuantity"
              id="invest-quantity"
              type="number"
              min="100"
              max="1000000"
              onChange={this.handleWaterChange}
              value={this.state.investQuantity}
              required
            />

            <button
              id="invest-type"
              onClick={(e) => {
                e.preventDefault();
                this.updateInvestAction();
              }}
            >
              <img src={require('../../src/assets/images/market/items/water-bottle.png')} alt="Water" />
            </button>

            <button id="submit-invest" onClick={() => this.submitInvest}>
              <p>{this.state.investAction}</p>
            </button>

            <button
              id="invest-price"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <p className="for-and-price">
                FOR{' '}
                <input
                  name="investPrice"
                  id="gold-quantity"
                  type="number"
                  min="1"
                  max="50000"
                  maxValue="50000"
                  onChange={this.handleGoldChange}
                  value={this.state.investPrice}
                  required
                />
              </p>

              <img src={require('../../src/assets/images/market/gold-coins.png')} alt="Gold Coins" />
            </button>
          </form>
        </div>
        <div id="bankcharts" style={{ width: '100%', height: '500px' }}></div>
      </div>
    );
  }
}
