import React, { Component } from 'react';

class RessourcesBar extends Component {
  fetchProductionRates;
  ressourcesUpdator;
  constructor(props) {
    super(props);
    this.state = {
      ressources: '',
      hidden: false,
    };
  }

  componentDidMount() {
    this.fetchRessources();
    this.fetchProductionRates = setInterval(() => {
      this.fetchRessources();
    }, 5000);
    this.ressourcesUpdator = setInterval(() => this.updateValue(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.ressourcesUpdator);
    clearInterval(this.fetchProductionRates);
  }

  updateValue() {
    var ressources = this.state.ressources;
    //Iron
    ressources.iron += Number((this.state.ressources.buildings.productions.foundery / 60 / 60).toFixed(2));
    ressources.iron = Number(ressources.iron.toFixed(2));
    //Water
    ressources.water += Number((this.state.ressources.buildings.productions.water_castle / 60 / 60).toFixed(2));
    ressources.water = Number(ressources.water.toFixed(2));
    // Oil (Extractor + Platform)
    ressources.oil += Number((this.state.ressources.buildings.productions.oil_extractor / 60 / 60).toFixed(2));
    ressources.oil += Number((this.state.ressources.buildings.productions.oil_platform / 60 / 60).toFixed(2));
    ressources.oil = Number(ressources.oil.toFixed(2));
    // Energy (Nuclear + Eolienne)
    ressources.energy += Number((this.state.ressources.buildings.productions.nuclear / 60 / 60).toFixed(2));
    ressources.energy += Number((this.state.ressources.buildings.productions.eolienne / 60 / 60).toFixed(2));
    ressources.energy = Number(ressources.energy.toFixed(2));

    this.setState({
      ressources: ressources,
    });
  }

  fetchRessources = async () => {
    const data = await fetch('/api/buildings/get_ressources');
    if (data.status === 200) {
      const ressources = await data.json();
      // console.log(ressources);
      this.setState({
        ressources: ressources,
      });
    } else {
      console.log(data.statusText);
    }
  };

  // Format displaying numbers
  formatNumber(number) {
    number = Number(number.toFixed(0));
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      var new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  hideOrShow() {
    const bar = document.getElementsByClassName('ressources-bar')[0];

    if (this.state.hidden) {
      // On affiche
      this.setState({
        hidden: false,
      });
      bar.style.height = '223px';
    } else {
      // On masque
      this.setState({
        hidden: true,
      });
      bar.style.height = '50px';
      bar.style.overflow = 'hidden';
    }
  }

  render() {
    return (
      <>
        {this.state.ressources ? (
          <div className="ressources-bar" onClick={() => this.hideOrShow()}>
            {this.state.hidden ? (
              <div className="ressources-container">
                <div className="ressource-img-container">
                  <img
                    src={require(`../assets/images/ressources/ressourcesBar/arrow-dollar.png`)}
                    alt="Arrow Dollar Icon"
                  />
                </div>
                <p id="show-ressources-text">Ressources</p>
              </div>
            ) : (
              <span></span>
            )}

            <div className="ressources-container">
              <div className="ressource-img-container">
                <img src={require(`../assets/images/ressources/ressourcesBar/gold-bars.png`)} alt="Gold Icon" />
              </div>
              <p>{this.formatNumber(this.state.ressources.gold)}</p>
            </div>

            <div className="ressources-container">
              <div className="ressource-img-container">
                <img src={require(`../assets/images/ressources/ressourcesBar/iron-bars.png`)} alt="Iron Icon" />
              </div>
              <p>{this.formatNumber(this.state.ressources.iron)}</p>
            </div>

            <div className="ressources-container">
              <div className="ressource-img-container">
                <img src={require(`../assets/images/ressources/ressourcesBar/oil-baril.png`)} alt="Oil Icon" />
              </div>
              <p>{this.formatNumber(this.state.ressources.oil)}</p>
            </div>

            <div className="ressources-container">
              <div className="ressource-img-container">
                <img src={require(`../assets/images/ressources/ressourcesBar/water-drop.png`)} alt="Water Icon" />
              </div>
              <p>{this.formatNumber(this.state.ressources.water)}</p>
            </div>

            <div className="ressources-container">
              <div className="ressource-img-container">
                <img src={require(`../assets/images/ressources/ressourcesBar/energy-thunder.png`)} alt="Energy Icon" />
              </div>
              <p>{this.formatNumber(this.state.ressources.energy)}</p>
            </div>
          </div>
        ) : (
          <span></span>
        )}
      </>
    );
  }
}

export default RessourcesBar;
