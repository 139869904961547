import React from 'react';
import { Component } from 'react';
import SpyReport from './SpyReport';
import AttackReport from './AttackReport';
import HelpContent from './HelpContent';
import RealEstate from './RealEstate';
import Productions from './Productions';
import SellHistoryItem from './SellHistoryItem';
import AttackHistoryItem from './AttackHistoryItem';
import SpyHistoryItem from './SpyHistoryItem';

export default class Board extends Component {
  timeinterval;
  delayButtonsTimeout;
  callOnce = true;
  updatedOnceFontSize = false;
  constructor(props) {
    super(props);
    this.state = {
      delayClick: false,
      component_to_show: 'attack-reports',
      isProducing: false,
      isCreating: false,
      color_produce_button: '',
      weapon: 'knife',
      weapon_counter: 0,
      minuts: 0,
      seconds: 0,
      company_minuts: 0,
      company_seconds: 0,
      hiddenSells: false,
      sell_history_btn_text: 'Hide',
      attack_active_btn: 'active',
      spy_active_btn: '',
      attacked_active_btn: '',
      spied_active_btn: '',
      infos: {
        company: {},
        weaponPrices: {},
      },
    };
  }

  fetchNotifications = async () => {
    const data = await fetch('/api/notifications/get/board');
    if (data.status === 200) {
      const notifications = await data.json();
      this.setState({
        army_notifications: notifications,
        notif_attacked_count: 0,
        notif_spied_count: 0,
      });
      this.setNotifications(); // on display au bon endroit selon la nav_category
    } else {
      console.log(data.statusText);
    }
  };

  setNotifications() {
    var notifs = this.state.army_notifications.notifications;
    notifs.forEach((notif) => {
      switch (notif.sub_category) {
        case 'attacked':
          this.setState({
            notif_attacked: true,
            notif_attacked_count: this.state.notif_attacked_count+1
          });
          break;
        case 'spied':
          this.setState({
            notif_spied: true,
            notif_spied_count: this.state.notif_spied_count+1
          });
          break;
        case 'market_sell':
          this.deleteNotificationFromDB('board', 'market_sell'); // on remove les notifs market sells quand on clique sur board
          break;
        default:
          break;
      }
    });
  }

  componentDidMount() {
    this.fetchData();
    this.fetchNotifications();
    if (typeof this.props.history.location.state !== 'undefined') {
      if (typeof this.props.history.location.state.toShow !== 'undefined') {
        this.setState({
          component_to_show: this.props.history.location.state.toShow,
        });
        if (typeof this.props.history.location.state.scrollTo !== 'undefined') {
          setTimeout(() => {
            const element_to_scroll = document.getElementsByClassName('attack-report-component')[0];
            window.scrollTo(0, element_to_scroll.getBoundingClientRect().top - 80);
          }, 800);
        }
      }
    }
    this.initializeClock();
    const self = this;
    setTimeout(() => {
      var sellHistoryContainer = document.getElementById('sell-history-container');
      if (sellHistoryContainer) {
        var pixelHeightValue = window.$('#sell-history-container').height();
        self.setState({
          container_sells_history_og_height: pixelHeightValue,
        });
        sellHistoryContainer.style.height = pixelHeightValue + 'px';
        if (self.state.infos.board_history.sell_history.length >= 3) {
          self.hideOrShowSells();
        }
        // rendre bouton Hide Or Show Sells cliquable que maintenant…
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeinterval);
    clearTimeout(this.timeOut);
    clearTimeout(this.delayButtonsTimeout);
  }

  getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minuts = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minuts,
      seconds,
    };
  }

  initializeClock = () => {
    var self = this;

    var now_hour = new Date().getHours(); // heure actuelle
    var deadline = new Date();
    deadline.setHours(now_hour + 1); // prochaine heure
    deadline.setMinutes(0);
    deadline.setSeconds(0);

    function updateClock() {
      const t = self.getTimeRemaining(deadline);

      self.setState({
        minuts: t.minuts, //.slice(-2),
        seconds: t.seconds, //.slice(-2),
      });

      // Appelé à chaque heure
      if (t.total <= 0) {
        clearInterval(self.timeinterval);
        self.timeOut = setTimeout(() => {
          self.fetchData();
          self.initializeClock();
        }, 1000); // au cas où le reset en backend prend un peu de temps
      }
    }

    if (self.callOnce) {
      updateClock();
      self.callOnce = false;
    }
    self.timeinterval = setInterval(updateClock, 1000);
  };

  initializeCompanyClock = () => {
    var self = this;
    var start_hour = new Date(Date.parse(this.state.infos.company.production.start_date));
    var deadline = new Date();
    deadline.setTime(start_hour.getTime() + 1 * 60 * 60 * 1000);

    function updateClock() {
      const t = self.getTimeRemaining(deadline);

      self.setState({
        company_minuts: t.minuts, //.slice(-2),
        company_seconds: t.seconds, //.slice(-2),
      });

      if (t.total <= 0) {
        clearInterval(self.timeinterval);
        self.timeOut = setTimeout(() => {
          fetch('/api/market/check-productions', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then((res) => {
              if (res.status === 200) {
                setTimeout(() => {
                  self.fetchData();
                }, 100);
              } else if (res.statusText) {
                this.setState({
                  error: res.statusText,
                });
              } else {
                const error = new Error(res.error);
                throw error;
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }, 50); // au cas où le reset en backend prend un peu de temps
      }
    }

    if (self.callOnce) {
      updateClock();
      self.callOnce = false;
    }
    self.timeinterval = setInterval(updateClock, 1000);
  };

  fetchData = async () => {
    const data = await fetch('/api/board/get');
    if (data.status === 200) {
      const infos = await data.json();
      // console.log(infos);
      this.setState({
        infos: infos,
      });
      // On définit le bon bouton et les bons reports à afficher
      if (infos.attack_reports.length >= 1) {
        this.setState({
          component_to_show: 'attack-reports',
          attack_active_btn: 'active',
          spy_active_btn: '',
          attacked_active_btn: '',
          spied_active_btn: '',
        });
      } else {
        if (infos.spy_reports.length >= 1) {
          this.setState({
            component_to_show: 'spy-reports',
            attack_active_btn: '',
            spy_active_btn: 'active',
            attacked_active_btn: '',
            spied_active_btn: '',
          });
        } else {
          if (infos.board_history.army_history.attacked.length >= 1) {
            this.setState({
              component_to_show: 'attacked-reports',
              attack_active_btn: '',
              spy_active_btn: '',
              attacked_active_btn: 'active',
              spied_active_btn: '',
            });
          } else {
            if (infos.board_history.army_history.spied.length >= 1) {
              this.setState({
                component_to_show: 'spied-reports',
                attack_active_btn: '',
                spy_active_btn: '',
                attacked_active_btn: '',
                spied_active_btn: 'active',
              });
            }
          }
        }
      }
      if (Object.keys(infos.company).length !== 0) {
        if (infos.company.production.producing === true) this.initializeCompanyClock();
      }
    } else {
      console.log(data.statusText);
    }
  };

  updateShowing(component) {
    const self = this;
    if (this.state.delayClick === false) {
      this.setState({
        delayClick: true,
      });
      this.delayButtonsTimeout = setTimeout(() => {
        self.setState({
          delayClick: false,
        });
      }, 1000);
      switch (component) {
        case 'spy-reports':
          this.setState({
            component_to_show: component,
            attack_active_btn: '',
            spy_active_btn: 'active',
            attacked_active_btn: '',
            spied_active_btn: '',
          });
          break;
        case 'attack-reports':
          this.setState({
            component_to_show: component,
            attack_active_btn: 'active',
            spy_active_btn: '',
            attacked_active_btn: '',
            spied_active_btn: '',
          });
          break;
        case 'spied-reports':
          this.setState({
            component_to_show: component,
            attack_active_btn: '',
            spy_active_btn: '',
            attacked_active_btn: '',
            spied_active_btn: 'active',
            notif_spied: false,
          });
          this.deleteNotificationFromDB('board', 'spied');
          break;
        case 'attacked-reports':
          this.setState({
            component_to_show: component,
            attack_active_btn: '',
            spy_active_btn: '',
            attacked_active_btn: 'active',
            spied_active_btn: '',
            notif_attacked: false,
          });
          this.deleteNotificationFromDB('board', 'attacked');
          break;

        default:
          break;
      }
    } else {
      console.log('Stop Spam');
    }
  }


  deleteNotificationFromDB(notification_type, sub_category) {
    fetch('/api/notifications/remove', {
      method: 'POST',
      body: JSON.stringify({ notification_type: notification_type, sub_category: sub_category }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log('Notification removed!');
        } else if (res.statusText) {
          console.log(res.statusText);
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  launchProduction = () => {
    if (this.state.isProducing === false) {
      this.setState({
        isProducing: true,
      });
      fetch('/api/market/launch-production', {
        method: 'POST',
        body: JSON.stringify({ weapon_type: this.state.weapon, quantity: 100 }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log('Production Launched!');
            this.fetchData();
          } else if (res.statusText) {
            console.log(res.statusText);
            this.setState({
              color_produce_button: 'red',
            });
            this.colorProduceButtonDisappear = setTimeout(() => {
              this.setState({
                isProducing: false,
                color_produce_button: '',
              });
            }, 2000);
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.log('Stop Spam');
    }
  };

  createCompany = () => {
    if (this.state.isCreating === false) {
      this.setState({
        isCreating: true,
      });
      fetch('/api/market/create-company', {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log('Company Created!');
            this.fetchData();
          } else if (res.statusText) {
            console.log(res.statusText);
            this.setState({
              color_create_button: 'red',
            });
            this.buttonColorCreateDisappear = setTimeout(() => {
              clearTimeout(this.colorProduceButtonDisappear);
              this.setState({
                isCreating: false,
                color_create_button: '',
              });
            }, 2000);
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.log('Stop Spam');
    }
  };

  getWeapon = (counter) => {
    if (counter >= 0 && counter <= 5) {
      var weapon = '';
      switch (counter) {
        case 0:
          weapon = 'knife';
          break;
        case 1:
          weapon = 'gun';
          break;
        case 2:
          weapon = 'rifle';
          break;
        case 3:
          weapon = 'rpg';
          break;
        case 4:
          weapon = 'sniper';
          break;
        case 5:
          weapon = 'missile';
          break;
        default:
          break;
      }
      this.setState({
        weapon_counter: counter,
        weapon: weapon,
      });
      // return weapon;
    } else if (counter >= 6) {
      this.setState({
        weapon_counter: 0,
        weapon: 'knife',
      });
    } else if (counter <= 0) {
      this.setState({
        weapon_counter: 5,
        weapon: 'missile',
      });
    }
  };

  // Format displaying numbers
  formatNumber(number) {
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      const new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  hideOrShowSells() {
    var sells = document.getElementById('sell-history-container');
    if (this.state.hiddenSells) {
      // On affiche
      this.setState({
        hiddenSells: false,
        sell_history_btn_text: 'Hide',
      });
      sells.style.height = this.state.container_sells_history_og_height + 'px';
    } else {
      // On masque
      this.setState({
        hiddenSells: true,
        sell_history_btn_text: 'Show',
      });
      sells.style.height = '110px';
    }
  }

  abbrNum(number, decPlaces) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);
    // Enumerate number abbreviations
    var abbrev = ['k', 'm', 'b', 't'];
    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {
      // Convert array index to "1000", "1000000", etc
      var size = Math.pow(10, (i + 1) * 3);
      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round((number * decPlaces) / size) / decPlaces;
        // Handle special case where we round up to the next abbreviation
        if (number === 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }
        // Add the letter for the abbreviation
        number += abbrev[i];
        // We are done... stop
        break;
      }
    }
    return number;
  }

  render() {
    return (
      <>
        <div id="title-board">
          <h1>
            <span role="img" aria-label="Emoji Board">
              🖥
            </span>{' '}
            Board
            {this.state.infos.avatar !== undefined ? (
              <div id="player-board-info">
                <p>
                  <img
                    src={require(`../assets/images/avatars/population/${this.state.infos.avatar}.png`)}
                    alt="Avatar"
                  />
                  {this.state.infos.username} <span className="board-rank">#{this.state.infos.rank}</span>
                  <span className="board-points">{this.state.infos.points}pts</span>
                </p>
              </div>
            ) : (
              console.log()
            )}
          </h1>
        </div>

        <div className="board-component">
          {this.state.infos.avatar !== undefined ? (
            <>
              {this.state.infos.active_status === false ? (
                <div className="about-pages-component" style={{ marginTop: '30px' }}>
                  <HelpContent validateButton={true} props={this.props} />
                </div>
              ) : (
                <>
                  <div id="spy-attack-counter-box">
                    <h3>Attacks / Spies</h3>
                    <p className="spy-attack-numbers">
                      <img src={require('../assets/images/army/sword-shield-spy-70/spy-icon.png')} alt="Spy Icon" />
                      {this.state.infos.spies_left}
                    </p>
                    <p className="spy-attack-numbers">
                      <img
                        id="sword-icon"
                        src={require('../assets/images/army/sword-shield-spy-70/sword.png')}
                        alt="Sword Icon"
                      />
                      {this.state.infos.attacks_left}
                    </p>
                    <div id="spy-attack-counter-clock">
                      <div>
                        <span className="left">{this.state.minuts}</span>
                      </div>
                      <div id="two-dots">
                        <span>:</span>
                      </div>
                      <div>
                        <span className="right">{this.state.seconds}</span>
                      </div>
                    </div>
                  </div>
                  <RealEstate
                    key={this.state.infos.rent}
                    props={this.props}
                    real_estate={this.state.infos.real_estate}
                    rent={this.state.infos.rent}
                    lands_bonus={this.state.infos.lands_bonus}
                    business_bonus={this.state.infos.business_tech_bonus}
                  />
                  <Productions
                    key={this.state.infos.productions}
                    props={this.props}
                    productions={this.state.infos.productions}
                  />

                  <div id="att-def-box">
                    <h3>Attack / Defense</h3>
                    <p className="spy-attack-numbers">
                      <img
                        id="sword-icon"
                        src={require('../assets/images/army/sword-shield-spy-70/sword.png')}
                        alt="Sword Icon"
                      />
                      {this.abbrNum(this.state.infos.attack, 2)}
                    </p>
                    <p className="spy-attack-numbers">
                      <img src={require('../assets/images/army/sword-shield-spy-70/shield.png')} alt="Shield Icon" />
                      {this.abbrNum(this.state.infos.defense, 2)}
                    </p>
                  </div>

                  <div id="company-container">
                    {Object.keys(this.state.infos.company).length !== 0 ? (
                      <>
                        <div className="company" style={{ boxShadow: '-20px 0px 20px 1px #0000008a' }}>
                          <h3>Company</h3>
                          <img src={require('../../src/assets/images/market/skycraper.png')} alt="" />
                          <button className="production-or-create-btn" style={{ borderLeft: '40px solid #2196f363' }}>
                            <p className="up-text-btn" style={{ left: '-28px' }}>
                              UP
                            </p>
                            <span className="first-line">
                              <p style={{ margin: '0 0 0 50px' }}>MAX</p>
                            </span>
                          </button>
                        </div>
                        <div className="company produce">
                          {this.state.infos.company.production.producing === false ? (
                            <>
                              <h3>
                                Produce <span id="times-100">[x100]</span>
                              </h3>
                              <img
                                src={require(`../../src/assets/images/army/weapons/${this.state.weapon}.png`)}
                                alt="Weapon"
                              />
                              <button id="next-btn" onClick={() => this.getWeapon(this.state.weapon_counter + 1)}>
                                <span role="img" aria-label="arrow-next">
                                  ➡️
                                </span>
                              </button>
                              <button id="prev-btn" onClick={() => this.getWeapon(this.state.weapon_counter - 1)}>
                                <span role="img" aria-label="arrow-prev">
                                  ⬅️
                                </span>
                              </button>
                              <button
                                className={`production-or-create-btn ${this.state.color_produce_button}`}
                                onClick={() => this.launchProduction()}
                              >
                                <p className="up-text-btn">START</p>
                                <span className="first-line">
                                  <img
                                    className="cost-icon"
                                    src={require(`../assets/images/ressources/twentypixels/iron-bars.png`)}
                                    alt="Iron Icon"
                                  />
                                  <p>
                                    {this.formatNumber(
                                      this.state.infos.weaponPrices[
                                        Object.keys(this.state.infos.weaponPrices)[this.state.weapon_counter]
                                      ].iron
                                    )}
                                  </p>
                                </span>

                                <span className="second-line">
                                  <img
                                    className="cost-icon"
                                    src={require(`../assets/images/ressources/twentypixels/oil-baril.png`)}
                                    alt="Oil Icon"
                                  />
                                  <p>
                                    {this.formatNumber(
                                      this.state.infos.weaponPrices[
                                        Object.keys(this.state.infos.weaponPrices)[this.state.weapon_counter]
                                      ].oil
                                    )}
                                  </p>
                                </span>
                              </button>
                            </>
                          ) : (
                            <>
                              <h3>
                                Producing <span id="times-100">[x100]</span>
                              </h3>
                              <img
                                src={require(`../../src/assets/images/army/weapons/${this.state.infos.company.production.weapon_type}.png`)}
                                alt="Weapon"
                              />

                              <div id="company-counter-clock">
                                <div>
                                  <span className="left">{this.state.company_minuts}</span>
                                </div>
                                <div id="two-dots">
                                  <span>:</span>
                                </div>
                                <div>
                                  <span className="right">{this.state.company_seconds}</span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <div
                        className="company"
                        style={{
                          boxShadow: '0px 0px 10px 5px rgb(0, 0, 0, 0.5)',
                          borderRadius: '5px',
                        }}
                      >
                        <h3>Company</h3>
                        <img src={require('../../src/assets/images/market/skycraper.png')} alt="" />
                        <button
                          className={`production-or-create-btn ${this.state.color_create_button}`}
                          onClick={() => this.createCompany()}
                        >
                          <p className="up-text-btn">CREATE</p>
                          <span className="first-line">
                            <img
                              className="cost-icon"
                              src={require(`../assets/images/ressources/twentypixels/gold-bars.png`)}
                              alt="Gold Icon"
                            />
                            <p>{this.formatNumber(100000)}</p>
                          </span>
                        </button>
                      </div>
                    )}
                    <p></p>
                  </div>

                  {this.state.infos.board_history !== undefined ? (
                    this.state.infos.board_history.sell_history.length >= 1 ? (
                      <div id="sell-history-container">
                        {this.state.infos.board_history.sell_history.length >= 3 ? (
                          <button onClick={() => this.hideOrShowSells()}>
                            {this.state.sell_history_btn_text} Sell History
                          </button>
                        ) : (
                          console.log()
                        )}
                        {this.state.infos.board_history !== undefined
                          ? this.state.infos.board_history.sell_history.map((sell_made) => (
                              <SellHistoryItem props={this.props} sell={sell_made} />
                            ))
                          : console.log()}
                      </div>
                    ) : (
                      console.log()
                    )
                  ) : (
                    console.log()
                  )}

                  <ul>
                    {this.state.infos.attack_reports !== undefined ? (
                      <>
                        {this.state.infos.attack_reports.length >= 1 ? (
                          <li>
                            <button
                              className={this.state.attack_active_btn}
                              onClick={() => this.updateShowing('attack-reports')}
                            >
                              My Attacks
                            </button>
                          </li>
                        ) : (
                          console.log()
                        )}
                        {this.state.infos.spy_reports.length >= 1 ? (
                          <li>
                            <button
                              className={this.state.spy_active_btn}
                              onClick={() => this.updateShowing('spy-reports')}
                            >
                              My Spyings
                            </button>
                          </li>
                        ) : (
                          console.log()
                        )}
                      </>
                    ) : (
                      console.log()
                    )}

                    {this.state.infos.board_history.army_history !== undefined ? (
                      <>
                        {this.state.infos.board_history.army_history.attacked.length >= 1 ? (
                          <li>
                            {this.state.notif_attacked ? <span className="notif-pastille">{this.state.notif_attacked_count}</span> : console.log()}
                            <button
                              className={this.state.attacked_active_btn}
                              onClick={() => this.updateShowing('attacked-reports')}
                            >
                              They Attacked
                            </button>
                          </li>
                        ) : (
                          console.log()
                        )}
                        {this.state.infos.board_history.army_history.spied.length >= 1 ? (
                          <li>
                            {this.state.notif_spied ? <span className="notif-pastille">{this.state.notif_spied_count}</span> : console.log()}
                            <button
                              className={this.state.spied_active_btn}
                              onClick={() => this.updateShowing('spied-reports')}
                            >
                              They Spied
                            </button>
                          </li>
                        ) : (
                          console.log()
                        )}
                      </>
                    ) : (
                      console.log()
                    )}
                  </ul>

                  {this.state.component_to_show === 'spy-reports'
                    ? this.state.infos.spy_reports.map((spy_report) => (
                        <SpyReport key={spy_report._id} spy_report={spy_report} props={this.props} />
                      ))
                    : console.log()}

                  {this.state.component_to_show === 'spied-reports'
                    ? this.state.infos.board_history !== undefined
                      ? this.state.infos.board_history.army_history.spied.map((spy) => (
                          <SpyHistoryItem key={spy._id} props={this.props} spy={spy} />
                        ))
                      : console.log()
                    : console.log()}

                  {this.state.component_to_show === 'attack-reports'
                    ? this.state.infos.attack_reports !== undefined
                      ? this.state.infos.attack_reports.map((attack_report) => (
                          <AttackReport key={attack_report._id} attack_report={attack_report} props={this.props} />
                        ))
                      : console.log()
                    : console.log()}

                  {this.state.component_to_show === 'attacked-reports'
                    ? this.state.infos.board_history !== undefined
                      ? this.state.infos.board_history.army_history.attacked.map((attack) => (
                          <AttackHistoryItem key={attack._id} props={this.props} attack={attack} />
                        ))
                      : console.log()
                    : console.log()}
                </>
              )}
            </>
          ) : (
            console.log()
          )}
        </div>
      </>
    );
  }
}
