import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class SpyReport extends Component {
  isAttacking = false;
  constructor(props) {
    super(props);
    this.state = {
      spy_report: this.props.spy_report,
      rang: '',
      avatar: '',
      online_status: '',
    };
  }

  componentDidMount() {
    this.fetchUserDetails(this.state.spy_report.spied_user_id);
  }

  fetchUserDetails = async (id) => {
    const url = '/api/profil/view/' + id;
    const data = await fetch(url);

    if (data.status === 200) {
      var userDetails = await data.json();
      // console.log('la réponse=', userDetails);
      this.setState({
        rang: userDetails.rang,
        avatar: userDetails.avatar,
        online_status: userDetails.online,
      });
    } else {
      // answer user details not found
      console.log('Spy Report User Details Not Found');
    }
  };

  adjustRang(rank) {
    if (rank === 2) {
      return '[admin]';
    } else if (rank === 1) {
      return '[mod]';
    } else {
      return '';
    }
  }

  // Format displaying numbers
  formatNumber(number) {
    number = parseFloat(number).toFixed(0);
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      var new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.pushToAttacks);
    clearTimeout(this.removeError);
  }

  loadingAnimation() {
    const id = `spy-report-${this.state.spy_report._id}`;
    const box = document.getElementById(id);
    const line = box.querySelector('#loading-line'); // '#' + 'loading-line'
    line.style.opacity = '1';
    line.style.width = '80.6%';

    //Redirige vers /board
    this.pushToAttacks = setTimeout(() => {
      this.props.props.history.push({
        pathname: '/board',
        state: { toShow: 'attack-reports', scrollTo: 'yes' },
      });
    }, 1000);
  }

  loadingError(error) {
    const id = `spy-report-${this.state.spy_report._id}`;
    const box = document.getElementById(id);
    const sentence = box.querySelector('#error-sentence'); // '#' + 'error-sentence'
    sentence.style.opacity = '1';
    sentence.textContent = error;
    this.removeError = setTimeout(() => {
      sentence.style.opacity = '0';
      setTimeout(() => {
        sentence.textContent = '';
        this.isAttacking = false;
      }, 500);
    }, 1500);
  }

  attack = (id) => {
    if (!this.isAttacking) {
      this.isAttacking = true;
      //console.log('attacking play n°', id);
      fetch('/api/army/attack', {
        method: 'POST',
        body: JSON.stringify({ id: id }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log('Attack Successful!');
            this.loadingAnimation();
          } else if (res.statusText) {
            this.loadingError(res.statusText);
            console.log(res.statusText);
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  render() {
    return (
      <>
        {this.state.rang !== '' ? (
          <div className="spy-report-component" id={`spy-report-${this.state.spy_report._id}`}>
            <h1>
              <span id="error-sentence"></span>Spy Report
            </h1>

            <div id="loading-line"></div>

            <div className="spied-user-details">
              <p className="spied-rank">
                <span className="rang-span" id={`rank-${this.state.rang}`}>
                  {this.adjustRang(this.state.rang)}
                </span>
              </p>
              {this.state.avatar ? (
                <img src={require(`../assets/images/avatars/${this.state.avatar}.png`)} alt="Avatar" />
              ) : (
                <span></span>
              )}

              <Link to={`/profil/view/${this.state.spy_report.spied_user_id}`}>
                <p className="spied-username">
                  <span className="status-span" id={`${this.state.online_status}`}></span>
                  {this.state.spy_report.spied_user_username}
                </p>
              </Link>
              <div id="background-box"></div>
            </div>

            <button onClick={() => this.attack(this.state.spy_report.spied_user_id)} id="spy-attack-btn">
              <img src={require(`../assets/images/army/sword.png`)} alt="Sword" />
              <p>Attack</p>
            </button>

            <p className="date-spy-report">{this.state.spy_report.date}</p>

            <div className="spy-report-army">
              <div className="spy-report-army-container">
                <div className="spy-report-army-img-container">
                  <img src={require(`../assets/images/army/units/mini/mini-soldier.png`)} alt="Soldier" />
                </div>
                <p>{this.formatNumber(this.state.spy_report.army_report.soldier)}</p>
              </div>
              <div className="spy-report-army-container">
                <div className="spy-report-army-img-container">
                  <img src={require(`../assets/images/army/units/mini/mini-tank.png`)} alt="Tank" />
                </div>
                <p>{this.formatNumber(this.state.spy_report.army_report.tank)}</p>
              </div>
              <div className="spy-report-army-container">
                <div className="spy-report-army-img-container">
                  <img src={require(`../assets/images/army/units/mini/mini-boat.png`)} alt="Boat" />
                </div>
                <p>{this.formatNumber(this.state.spy_report.army_report.boat)}</p>
              </div>
              <div className="spy-report-army-container">
                <div className="spy-report-army-img-container">
                  <img src={require(`../assets/images/army/units/mini/mini-plane.png`)} alt="Plane" />
                </div>
                <p>{this.formatNumber(this.state.spy_report.army_report.plane)}</p>
              </div>
            </div>

            <div className="spy-report-ressources">
              <div id="gold-report" className="spy-report-ressources-container">
                <div className="spy-report-ressources-img-container">
                  <img src={require(`../assets/images/ressources/spy-attack-reports/gold-bars.png`)} alt="Gold" />
                </div>
                <p>{this.formatNumber(this.state.spy_report.ressources_report.gold)}</p>
              </div>
              <div className="spy-report-ressources-container">
                <div className="spy-report-ressources-img-container">
                  <img src={require(`../assets/images/ressources/spy-attack-reports/iron-bars.png`)} alt="Iron" />
                </div>
                <p>{this.formatNumber(this.state.spy_report.ressources_report.iron)}</p>
              </div>
              <div className="spy-report-ressources-container">
                <div className="spy-report-ressources-img-container">
                  <img src={require(`../assets/images/ressources/spy-attack-reports/oil-baril.png`)} alt="Oil" />
                </div>
                <p>{this.formatNumber(this.state.spy_report.ressources_report.oil)}</p>
              </div>
              <div className="spy-report-ressources-container">
                <div className="spy-report-ressources-img-container">
                  <img src={require(`../assets/images/ressources/spy-attack-reports/water-drop.png`)} alt="Water" />
                </div>
                <p>{this.formatNumber(this.state.spy_report.ressources_report.water)}</p>
              </div>
              <div className="spy-report-ressources-container">
                <div className="spy-report-ressources-img-container">
                  <img
                    src={require(`../assets/images/ressources/spy-attack-reports/energy-thunder.png`)}
                    alt="Energy"
                  />
                </div>
                <p>{this.formatNumber(this.state.spy_report.ressources_report.energy)}</p>
              </div>
            </div>
          </div>
        ) : (
          console.log()
        )}
      </>
    );
  }
}
