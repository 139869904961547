import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../config';

export default class Population extends Component {
  constructor(props) {
    super(props);
    this.state = {
      population: [],
      page: 0,
    };
  }

  componentDidMount() {
    this.fetchPopulation(this.state.page);
  }

  fetchPopulation = async (page) => {
    const data = await fetch('/api/population/get/' + page);
    const population = await data.json();
    //console.log(population);
    this.setState({
      population: population,
      entirePopulation: population,
      page: page,
    });
  };

  loadNextUsers = () => {
    if (this.state.population.length >= 50) {
      const page_to_fetch = this.state.page + 1;
      this.fetchPopulation(page_to_fetch);
    }
  };

  loadPreviousUsers = () => {
    const page = this.state.page;
    if (page > 0) {
      this.fetchPopulation(page - 1);
    }
  };

  handleInputChange = (event) => {
    const viewerId = this.state.population[0].viewerId;
    const { value, name } = event.target;
    if (value === '') {
      const post = document.getElementById('search');
      post.placeholder = 'Player Search';
      const entirePopulation = this.state.entirePopulation;
      this.setState({
        population: entirePopulation,
      });
    }
    this.setState({
      [name]: value,
    });

    if (value !== '') {
      var newPopulation = [];
      this.state.entirePopulation.forEach((user) => {
        const lowered_username = user.username.toLowerCase();
        if (lowered_username.indexOf(value.toLowerCase()) === 0) {
          newPopulation.push(user);
          newPopulation[0].viewerId = viewerId;
          this.setState({
            population: newPopulation,
          });
        }
      });
    }
  };

  launchAnimation(user, success, text) {
    var bande = document.getElementsByName(user)[0];
    var saved_nodes = [];
    bande.childNodes.forEach((element) => {
      saved_nodes.push(element);
      bande.removeChild(element);
      while (bande.children[0]) {
        saved_nodes.push(bande.children[0]);
        bande.removeChild(bande.children[0]);
      }
    });
    const online_status = bande.classList[1];
    var para = document.createElement('p');
    var node = document.createTextNode(text);
    para.appendChild(node);

    if (success) {
      // success display
      if (online_status === 'false') {
        bande.style.borderLeft = '5px solid green';
      }
      para.style.marginLeft = '65px';
      bande.appendChild(para);
      bande.style.color = 'white';
      bande.style.background = 'linear-gradient(45deg, green, green, rgb(74 185 4))';
    } else {
      // error display
      if (online_status === 'true') {
        bande.style.borderLeft = '5px solid red';
      }
      para.style.marginLeft = '55px';
      bande.appendChild(para);
      bande.style.color = 'white';
      bande.style.background = 'linear-gradient(45deg, red, #860404e0)';
    }

    // On remet la bande comme avant
    setTimeout(() => {
      bande.style.color = 'black';
      bande.style.background = 'white';
      bande.removeChild(para);
      saved_nodes.forEach((node) => {
        bande.appendChild(node);
      });
      if (online_status === 'false') {
        bande.style.borderLeft = '5px solid red';
      } else {
        bande.style.borderLeft = '5px solid green';
      }
      this.fetchPopulation(this.state.page);
    }, 2000);
  }

  spy(id) {
    fetch('/api/army/spy', {
      method: 'POST',
      body: JSON.stringify({ id: id }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.launchAnimation(id, true, 'Successful Spying!');
          let audio = new Audio(require('../../src/assets/sounds/spy.mp3'));
          audio.volume = global.config.sound.volume;
          audio.play();
        } else if (res.statusText) {
          this.launchAnimation(id, false, res.statusText);
          console.log(res.statusText);
          let audio = new Audio(require('../../src/assets/sounds/error.mp3'));
          audio.volume = global.config.sound.volume;
          audio.play();
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  attack(id) {
    fetch('/api/army/attack', {
      method: 'POST',
      body: JSON.stringify({ id: id }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.statusText === 'You Won!') {
            this.launchAnimation(id, true, 'You Won!');
            let audio = new Audio(require('../../src/assets/sounds/attack.mp3'));
            audio.volume = global.config.sound.volume;
            audio.play();
          } else if (res.statusText === 'You Lost!') {
            this.launchAnimation(id, false, 'You Lost!');
            let audio = new Audio(require('../../src/assets/sounds/lostfight.mp3'));
            audio.volume = global.config.sound.volume;
            audio.play();
          }
        } else if (res.status !== 200) {
          this.launchAnimation(id, false, res.statusText);
          console.log(res.statusText);
          let audio = new Audio(require('../../src/assets/sounds/error.mp3'));
          audio.volume = global.config.sound.volume;
          audio.play();
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    const self = this;
    return (
      <>
        <div id="arrow-title">
          {(function () {
            if (self.state.population.length >= 1) {
              if (self.state.population[0].hasMore) {
                return (
                  <button id="next-btn-inbox" onClick={self.loadNextUsers}>
                    <span role="img" aria-label="Next">
                      ➡️
                    </span>
                  </button>
                );
              }
            }
          })()}

          {this.state.page > 0 ? (
            <button id="previous-btn-inbox" onClick={this.loadPreviousUsers}>
              <span role="img" aria-label="Back">
                ⬅️
              </span>
            </button>
          ) : (
            <span></span>
          )}
          <div id="title-population">
            <h1>
              <span role="img" aria-label="Emoji Population">
                👨‍👩‍👦‍👦
              </span>{' '}
              Population
              <form>
                <input
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Player Search"
                  maxLength="15"
                  onChange={this.handleInputChange}
                />
              </form>
            </h1>
          </div>
        </div>

        <div className="population-component">
          {this.state.population.map((user) => (
            <section key={user.id} name={user.id} className={`bande ${user.online}`}>
              <div id="bande-text-content">
                <p>
                  <Link to={`/profil/view/${user.id}`}>{user.username}</Link>
                </p>
                <p>{user.points} pts</p>
              </div>
              <img src={require(`../assets/images/avatars/population/${user.avatar}.png`)} alt="Avatar" />
              {user.id !== self.state.population[0].viewerId ? (
                <div id="attack-spy">
                  <button onClick={() => this.spy(user.id)}>
                    <img
                      id="spy-icon"
                      src={require('../assets/images/spy-icon.png')}
                      title="Spy Player"
                      alt="Spy Player"
                    />
                  </button>
                  <button onClick={() => this.attack(user.id)}>
                    <img
                      id="attack-icon"
                      src={require('../assets/images/army/sword.png')}
                      title="Attack Player"
                      alt="Attack Player"
                    />
                  </button>
                </div>
              ) : (
                <span></span>
              )}
            </section>
          ))}
        </div>
      </>
    );
  }
}
