import React from 'react';
import { Component } from 'react';
import '../config';

export default class Technologies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      technologies: '',
      count: 0,
      armor_techColorState: 'blue',
      business_techColorState: 'blue',
      laser_techColorState: 'blue',
      spy_techColorState: 'blue',
    };
  }

  componentDidMount() {
    this.fetchTechnologies();
  }
  
  componentWillUnmount() {
    if (this.decreaseCounter) clearTimeout(this.decreaseCounter);
  }

  fetchTechnologies = async () => {
    const url = '/api/technologies/get';
    const data = await fetch(url);
    const technologies = await data.json();
    //console.log(technologies);

    const copy = JSON.parse(JSON.stringify(technologies.effects));
    this.setState({
      technologies: technologies,
      effects: copy, // à afficher
    });
  };

  updateTechnology(technology) {
    // Afin d'éviter le spam des boutons
    if (this.state.count === 0) {
      this.setState({
        count: 2,
      });
      this.decreaseCounter = setTimeout(() => {
        this.setState({
          count: 0,
        });
      }, 2000);

      fetch('/api/technologies/level_up', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ technology: technology }),
      }).then((res) => {
        if (res.status === 200) {
          let audio = new Audio(require('../../src/assets/sounds/techno.mp3'));
          audio.volume = global.config.sound.volume;
          audio.play();
          this.fetchTechnologies();
          // On peut réupdate directement après un update
          this.setState({
            count: 0,
          });
        } else {
          let audio = new Audio(require('../../src/assets/sounds/error.mp3'));
          audio.volume = global.config.sound.volume;
          audio.play();
          switch (res.statusText) {
            case 'armor_tech':
              this.setState({
                armor_techColorState: 'red',
              });
              break;
            case 'business_tech':
              this.setState({
                business_techColorState: 'red',
              });
              break;
            case 'oil_platform':
              this.setState({
                oil_platformColorState: 'red',
              });
              break;
            case 'laser_tech':
              this.setState({
                laser_techColorState: 'red',
              });
              break;
            case 'spy_tech':
              this.setState({
                spy_techColorState: 'red',
              });
              break;
            default:
              break;
          }
          this.makeErrorDisappear = setTimeout(() => {
            this.setState({
              armor_techColorState: 'blue',
              business_techColorState: 'blue',
              laser_techColorState: 'blue',
              spy_techColorState: 'blue',
            });
          }, 2000);
        }
      });
    } else {
      console.log('Spam Alert');
    }
  }

  formatNumber(number) {
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      const new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  updateInputColorLeave = (event) => {
    const toShow = JSON.parse(JSON.stringify(this.state.technologies.effects));
    this.setState({
      armor_techColorState: 'blue',
      business_techColorState: 'blue',
      laser_techColorState: 'blue',
      spy_techColorState: 'blue',
      effects: toShow,
    });
  };

  // Updating input color when hovering button
  updateInputColorEnter = (event) => {
    const { value, name } = event.target; // voir si virer value

    var news = this.state.effects;
    switch (name) {
      case 'armor_tech':
        news.armor_tech = this.state.technologies.future_effects.armor_tech;
        this.setState({
          armor_techColorState: 'green',
          effects: news,
        });
        break;
      case 'business_tech':
        news.business_tech = this.state.technologies.future_effects.business_tech;
        this.setState({
          business_techColorState: 'green',
          effects: news,
        });
        break;
      case 'laser_tech':
        news.laser_tech = this.state.technologies.future_effects.laser_tech;
        this.setState({
          laser_techColorState: 'green',
          effects: news,
        });
        break;
      case 'spy_tech':
        news.spy_tech = this.state.technologies.future_effects.spy_tech;
        this.setState({
          spy_techColorState: 'green',
          effects: news,
        });
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <>
        {this.state.technologies ? (
          <>
            <div id="title-technologies">
              <h1>
                <span role="img" aria-label="Emoji Technologies">
                  📡
                </span>{' '}
                Technologies
              </h1>
            </div>

            <div className="technologies-component animate__animated animate__fadeIn">
              <div className="single-tech-component">
                <h4>Armor</h4>
                <p className="lvl"> [Level: {this.state.technologies.armor_tech}]</p>
                <img src={require(`../assets/images/technologies/tech-armor.png`)} alt="Armor Technology" />
                <p className="result">
                  <img
                    className="result-icon"
                    src={require(`../assets/images/army/smaller/shield.png`)}
                    alt="Shield Icon"
                  />{' '}
                  {this.formatNumber(this.state.effects.armor_tech)}
                </p>
                <button
                  name="armor_tech"
                  className={this.state.armor_techColorState}
                  onClick={() => this.updateTechnology('armor_tech')}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">UP</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/gold-bars.png`)}
                      alt="Gold Icon"
                    />
                    <p>{this.formatNumber(this.state.technologies.prices.armor_tech)}</p>
                  </span>
                </button>
              </div>

              <div className="single-tech-component">
                <h4>Business Boost</h4>
                <p className="lvl"> [Level: {this.state.technologies.business_tech}]</p>
                <img src={require(`../assets/images/technologies/tech-network.png`)} alt="Market Technology" />
                <p className="result">
                  <img
                    className="result-icon"
                    src={require(`../assets/images/market/smaller/gold-coins.png`)}
                    alt="Coins Icon"
                  />{' '}
                  {this.formatNumber(this.state.effects.business_tech)}%
                </p>
                {this.state.technologies.effects.business_tech >= 100 ? (
                  <button name="business_tech" className="red">
                    <p className="up-text-btn">UP</p>
                    <span className="first-line">
                      <p className="level-max">MAX</p>
                    </span>
                  </button>
                ) : (
                  <button
                    name="business_tech"
                    className={this.state.business_techColorState}
                    onClick={() => this.updateTechnology('business_tech')}
                    onMouseEnter={this.updateInputColorEnter}
                    onMouseLeave={this.updateInputColorLeave}
                  >
                    <p className="up-text-btn">UP</p>
                    <span className="first-line">
                      <img
                        className="cost-icon"
                        src={require(`../assets/images/ressources/twentypixels/gold-bars.png`)}
                        alt="Gold Icon"
                      />
                      <p>{this.formatNumber(this.state.technologies.prices.business_tech)}</p>
                    </span>
                  </button>
                )}
              </div>

              <div className="single-tech-component">
                <h4>Lazer</h4>
                <p className="lvl"> [Level: {this.state.technologies.laser_tech}]</p>
                <img src={require(`../assets/images/technologies/tech-laser.png`)} alt="Laser Technology" />
                <p className="result">
                  <img
                    className="result-icon"
                    src={require(`../assets/images/army/smaller/sword.png`)}
                    alt="Sword Icon"
                  />{' '}
                  {this.formatNumber(this.state.effects.laser_tech)}
                </p>
                <button
                  name="laser_tech"
                  className={this.state.laser_techColorState}
                  onClick={() => this.updateTechnology('laser_tech')}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">UP</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/gold-bars.png`)}
                      alt="Gold Icon"
                    />
                    <p>{this.formatNumber(this.state.technologies.prices.laser_tech)}</p>
                  </span>
                </button>
              </div>

              <div className="single-tech-component">
                <h4>Spy</h4>
                <p className="lvl"> [Level: {this.state.technologies.spy_tech}]</p>
                <img src={require(`../assets/images/technologies/tech-spy.png`)} alt="Spy Technology" />
                <p className="result">
                  <span role="img" aria-label="Emoji">
                    🔮
                  </span>{' '}
                  {this.formatNumber(this.state.effects.spy_tech)}%
                </p>
                {this.state.technologies.effects.spy_tech >= 100 ? (
                  <button name="spy_tech" className="red">
                    <p className="up-text-btn">UP</p>
                    <span className="first-line">
                      <p className="level-max">MAX</p>
                    </span>
                  </button>
                ) : (
                  <button
                    name="spy_tech"
                    className={this.state.spy_techColorState}
                    onClick={() => this.updateTechnology('spy_tech')}
                    onMouseEnter={this.updateInputColorEnter}
                    onMouseLeave={this.updateInputColorLeave}
                  >
                    <p className="up-text-btn">UP</p>
                    <span className="first-line">
                      <img
                        className="cost-icon"
                        src={require(`../assets/images/ressources/twentypixels/gold-bars.png`)}
                        alt="Gold Icon"
                      />
                      <p>{this.formatNumber(this.state.technologies.prices.spy_tech)}</p>
                    </span>
                  </button>
                )}
              </div>
            </div>
          </>
        ) : (
          <span></span>
        )}
      </>
    );
  }
}
