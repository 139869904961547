import React from 'react';
import { Component } from 'react';

export default class HelpContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validate_button: this.props.validateButton,
    };
  }

  submitActive = () => {
    fetch('/api/auth/set-active-status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log('Player Set To Active!');
          this.props.props.history.push({
            pathname: '/board',
          });
        } else if (res.statusText) {
          console.log(res.statusText);
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    return (
      <>
        <h1>Rules</h1>

        <h4>
          <span role="img" aria-label="Emoji Settings">
            🔧
          </span>{' '}
          Settings
        </h4>
        <p>
          Dokime is in beta version, so it is working best with Chrome browser for the moment. It is advised to use a computer
          screen to visit Dokime, since it has not been made for mobile phones yet. Maybe one day!
        </p>
        <h4>
          <span role="img" aria-label="Emoji Goal">
            🎯
          </span>{' '}
          Goal
        </h4>
        <p>
          Dokime is an online strategy game. Players are classified according to their points. The goal of Dokime is to
          be the first on the leaderboard.
        </p>
        <h4>
          <span role="img" aria-label="Emoji Points">
            🏆
          </span>{' '}
          Points
        </h4>
        <p>
          Each player starts with 0 points. By winning a fight, a point is always won. By losing a fight, a point is
          always lost.
        </p>
        <h4>
          <img
            src={require('../../src/assets/images/market/gold-coins.png')}
            alt="Gold Coins"
            style={{ verticalAlign: 'middle', marginBottom: '3px' }}
          />{' '}
          Gold
        </h4>
        <p>
          Gold is Dokime's money. It allows you to create a company, buy lands, trade water on the market, ressources
          and weapons with other players. Thanks to gold, you can also send messages to other players.
        </p>
        <h4>
          <span role="img" aria-label="Emoji Buildings">
            🏬
          </span>{' '}
          Buildings
        </h4>
        <p>
          Different buildings produce every second different types of ressources. The more you upgrade a building, the
          more it will produce.
        </p>

        <h4>
          <span role="img" aria-label="Emoji Army">
            🏋🏼
          </span>{' '}
          Army
        </h4>
        <p>
          Your army can be composed of soldiers, tanks, boats and planes. Each unit bought increases your global attack
          and defense capacity. You lose units when attacking or when being attacked.
        </p>

        <h4>
          <span role="img" aria-label="Emoji Weapons">
            🪓
          </span>{' '}
          Weapons
        </h4>
        <p>
          You can produce weapons when you own a company. Each production lasts one hour and crafts 100 weapons at a
          time. You can trade your weapons with other players, or equip them on your own army. Each weapon adds attack
          value to your army. Missiles can only be equipped to tanks, boats and planes. They will add the most attack
          value when equipped to planes, then to boats and lastly to tanks. You lose equiped weapons when you attack but
          also when you're attacked. You can't lose unequiped weapons unless you sell them.
        </p>

        <h4>
          <span role="img" aria-label="Emoji Attack">
            ⚔️
          </span>{' '}
          Attack System
        </h4>
        <p>
          Every hour, you can attack 10 times. You can only attack 3 times the same player every hour. For two players
          to engage in a fight, they must have less than 20 points of difference. The loser loses a percentage of his
          ressources. Winner and loser both lose a percentage of their army, depending on their respective army
          configuration.
        </p>

        <h4>
          <span role="img" aria-label="Emoji Technologies">
            📡
          </span>{' '}
          Technologies
        </h4>
        <p>
          A technology upgrade is paid in gold. Lazer technology increases your attack. Armor technology increases your
          defense. Spy technology sets you a success rate for spying attempts. Business boost technology increases the
          rents you get from your real estate rents.
        </p>

        <h4>
          <span role="img" aria-label="Emoji Spy">
            🔮
          </span>{' '}
          Spy
        </h4>
        <p>
          As for attacking, you can spy 10 times per hour. Unlike attacking, you can spy a player as many times as you
          like. Your spying technology has a success rate increasing with its level. A successful spying reveals the
          spied user's ressources and army only, not his technologies.
        </p>

        <h4>
          <span role="img" aria-label="Emoji Real-Estate">
            🏡
          </span>{' '}
          Real Estate
        </h4>
        <p>Real Estate can be bought in gold. Real Estate will give you daily gold rents, always at 12am.</p>

        <h4>
          <span role="img" aria-label="Emoji Lands">
            🗺
          </span>{' '}
          Lands
        </h4>
        <p>
          Lands can be bought in gold. Lands will increase by a certain percentage the rents you get from your real
          estate investments. Lands will also allow you to show your success to other players. You can sell them to
          other players on the market at the price you like.
        </p>

        <h4>
          <img
            src={require('../../src/assets/images/market/market-trade.png')}
            alt="Market"
            style={{ verticalAlign: 'middle', marginBottom: '5px' }}
          />{' '}
          Market
        </h4>
        <p>
          The market allows you to sell to other players your own weapons, ressources and lands at the price you want.
          Trading with players is not limited, but you can only have 10 items at the same time on the market. You can
          also trade water and gold with the bank up to 15 times per day. Every 8 minuts, a new index sets the price of
          water in gold.
        </p>

        <h4>
          <span role="img" aria-label="Emoji Profile">
            👨🏼‍💻
          </span>{' '}
          Profile
        </h4>
        <p>Your profile can be seen by other players of Dokime. You can customize your biography and your avatar.</p>

        <h4>
          <span role="img" aria-label="Emoji Inbox">
            📪
          </span>{' '}
          Inbox
        </h4>
        <p>
          Sending a message to a player costs 500 gold. Answering to a received message costs 100 gold. The gold paid
          for the message doesn't go to the player whom you contact, it's just lost.
        </p>

        <h4>
          <span role="img" aria-label="Emoji Forum">
            ✏️
          </span>{' '}
          Forum
        </h4>
        <p>
          We vividly encourage you to express yourself and your ideas about how to improve Dokime on our forum. Feel
          also free to discuss whatever you want in a friendly way. You can edit your post/answer once every 10 minuts.
          Moderation team has the right to lock your topic or to mute you from the forum. Forum is yours, enjoy it!
        </p>

        <h4>
          <span role="img" aria-label="Emoji Chat">
            ✏️
          </span>{' '}
          Chat
        </h4>
        <p>
          The chat displays the last 10 messages posted by players of Dokime. Anyone can express himself on the chat,
          unless he/she has been muted by moderation. You can see who is using the Chat at the same time as you are.
        </p>

        {this.state.validate_button ? (
          <>
            <button id="validate-active-btn" onClick={() => this.submitActive()}>
              Activate My Account
            </button>
            <p id="confirm-p">
              Once your account is activated, you will have access to the game. You will appear among Population and
              other players will be able to attack you and steal your ressources. If not activated, your account will be
              deleted in 24 hours from now.
            </p>
          </>
        ) : (
          console.log()
        )}
      </>
    );
  }
}
