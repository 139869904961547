import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import '../config';

export default class Profil extends Component {
  spying = false;
  attacking = false;
  constructor(props) {
    super(props);
    //Set default message
    this.state = {
      username: '',
      biography: '',
      avatar: '',
      inscription_date: '',
      rang: '',
      online: '',
      id: '',
      otherMemberId: props.match.params.otherMemberId,
    };
  }

  componentDidMount() {
    if (typeof this.state.otherMemberId === 'undefined') {
      // absence de /view/x, affiche propre profil
      this.fetchProfil();
    } else {
      // si /view/x
      if (RegExp('^[0-9]*$').test(this.state.otherMemberId)) {
        // si /view/3, affiche profil de membre
        this.fetchOtherMember(this.state.otherMemberId);
      } else if (RegExp('[A-z]*$').test(this.state.otherMemberId)) {
        // si /view/lettres+nombre (error) redirige /error
        this.props.history.push('/error');
      }
    }
  }

  fetchOtherMember = async (param) => {
    const url = '/api/profil/view/' + param;
    const data = await fetch(url);
    const profil = await data.json();
    //console.log(profil);

    if (data.status === 200) {
      this.setState({
        username: profil.username,
        biography: profil.biography,
        avatar: profil.avatar,
        lastActivity: profil.lastActivity,
        inscription_date: this.adjustDate(profil.inscription_date),
        rang: profil.rang,
        online: profil.online,
        isOwnProfil: profil.isOwnProfil,
      });
    } else {
      // profil inexistant
      this.props.history.push('/error');
    }
  };

  fetchProfil = async () => {
    const data = await fetch('/api/profil');
    const profil = await data.json();
    //console.log(profil);

    this.setState({
      username: profil.username,
      biography: profil.biography,
      avatar: profil.avatar,
      inscription_date: this.adjustDate(profil.inscription_date),
      rang: profil.rang,
      online: profil.online,
      isOwnProfil: true,
    });
  };

  writeMessage() {
    this.props.history.push({
      pathname: '/inbox/write',
      state: { receiver: this.state.username, receiver_id: this.state.otherMemberId },
    });
  }

  launchAnimation(success, text) {
    var profil_component = document.getElementsByClassName('profil-component')[0];
    var para = document.createElement('p');
    const text_node = document.createTextNode(text);
    if (success) {
      para.style.color = 'green';
    } else {
      para.style.color = 'red';
    }
    para.appendChild(text_node);
    para.classList.add('spy-para');
    para.classList.add('animate__animated');
    para.classList.add('animate__fadeIn');
    profil_component.appendChild(para);

    setTimeout(() => {
      para.classList.remove('animate__fadeIn');
      para.classList.add('animate__fadeOut');
      setTimeout(() => {
        profil_component.removeChild(para);
        this.spying = false;
        this.attacking = false;
      }, 500);
    }, 2000);
  }

  spy(id) {
    if (!this.spying && !this.attacking) {
      this.spying = true;
      fetch('/api/army/spy', {
        method: 'POST',
        body: JSON.stringify({ id: id }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.launchAnimation(true, 'Successful Spying!');
            let audio = new Audio(require('../../src/assets/sounds/spy.mp3'));
            audio.volume = global.config.sound.volume;
            audio.play();
          } else if (res.statusText) {
            this.launchAnimation(false, res.statusText);
            console.log(res.statusText);
            let audio = new Audio(require('../../src/assets/sounds/error.mp3'));
            audio.volume = global.config.sound.volume;
            audio.play();
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  attack(id) {
    if (!this.attacking && !this.spying) {
      this.attacking = true;
      fetch('/api/army/attack', {
        method: 'POST',
        body: JSON.stringify({ id: id }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.statusText === 'You Won!') {
              this.launchAnimation(true, 'You Won!');
              let audio = new Audio(require('../../src/assets/sounds/attack.mp3'));
              audio.volume = global.config.sound.volume;
              audio.play();
            } else if (res.statusText === 'You Lost!') {
              this.launchAnimation(false, 'You Lost!');
              let audio = new Audio(require('../../src/assets/sounds/lostfight.mp3'));
              audio.volume = global.config.sound.volume;
              audio.play();
            }
          } else if (res.status !== 200) {
            this.launchAnimation(false, res.statusText);
            console.log(res.statusText);
            let audio = new Audio(require('../../src/assets/sounds/error.mp3'));
            audio.volume = global.config.sound.volume;
            audio.play();
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  render() {
    var previousPage;
    if (typeof this.props.history.location.state !== 'undefined') {
      previousPage = this.props.history.location.state.previousPage;
    }
    return (
      <div className="profil-component">
        <h1>Profile</h1>

        {previousPage === 'loadProfilFromNavBar' ? (
          <span></span>
        ) : (
          <button id="link-back" onClick={() => this.props.history.goBack()}>
            <span role="img" aria-label="Retour">
              ⬅️
            </span>
          </button>
        )}

        {this.state.avatar ? (
          <img src={require(`../assets/images/avatars/${this.state.avatar}.png`)} alt="Avatar" />
        ) : (
          <p></p>
        )}
        <p className="username">
          <span className="status-span" id={`${this.state.online}`}></span>
          {this.state.username}
          <span className="rang-span" id={`rank-${this.state.rang}`}>
            {this.adjustRang(this.state.rang)}
          </span>
        </p>

        {this.state.isOwnProfil ? (
          <span></span>
        ) : (
          <>
            <button id="button-message" title="Send Message" onClick={() => this.writeMessage()}>
              <span role="img" aria-label="Emoji Letter">
                ✉️
              </span>
            </button>
            <button id="button-spy" title="Spy Player" onClick={() => this.spy(this.state.otherMemberId)}>
              <img src={require('../assets/images/spy-icon.png')} alt="Spy" />
            </button>
            <button
              id="button-attack"
              title="Attack Player"
              onClick={() => this.attack(parseInt(this.state.otherMemberId))}
            >
              <img src={require('../assets/images/army/sword.png')} alt="Attack" />
            </button>
          </>
        )}

        <p className="biography">{this.state.biography}</p>
        <p className="inscription_date">{this.state.inscription_date}</p>

        {this.state.isOwnProfil ? (
          <Link to="/profil/update" title="Update Profile">
            <img
              id="modify-icon"
              src={require('../assets//images/profil-modify.png')}
              alt="modifier-profil"
              draggable="false"
            />
          </Link>
        ) : (
          <span></span>
        )}

        {this.state.online === false ? (
          <>
            <p className="last_seen">Last connexion: {this.state.lastActivity}</p>
          </>
        ) : (
          <span></span>
        )}
      </div>
    );
  }

  adjustRang(rank) {
    if (rank === 2) {
      return '[admin]';
    } else if (rank === 1) {
      return '[mod]';
    } else {
      return '';
    }
  }

  adjustDate(date_inscription) {
    //Script de date
    const time = new Date();
    //curent day
    const day = ('0' + time.getDate()).slice(-2);
    // current month
    const month = ('0' + (time.getMonth() + 1)).slice(-2);
    // current year
    const year = time.getFullYear();
    const today = month + '/' + day + '/' + year;

    var date_1 = new Date(today);
    var date_2 = new Date(date_inscription);

    var diff = Math.abs(date_1 - date_2);
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const phrase_pluriel = 'Member since ' + days + ' days';
    const phrase_singulier = 'Member since ' + days + ' day';

    if (days > 1) {
      return phrase_pluriel;
    } else {
      return phrase_singulier;
    }
  }
}
