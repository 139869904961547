import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../config';

class Nav extends Component {
  _redirecting = false;
  constructor(props) {
    super(props);
    this.state = {
      volume: 'on',
    };
  }

  componentDidMount() {
    this.fetchNotifications();
    this.notificationsSearcher = setInterval(() => this.fetchNotifications(), 5000);
  }

  componentWillUnmount() {
    if (this.notificationsSearcher) clearInterval(this.notificationsSearcher);
    if (this.disappearingNotif) clearTimeout(this.disappearingNotif);
  }

  fetchNotifications = async () => {
    const data = await fetch('/api/notifications/get');
    if (data.status === 200) {
      const notifications = await data.json();
      // console.log(notifications);
      this.setState({
        data: notifications,
        notif_forum: false,
        notif_forum_count: 0,
        notif_chat: false,
        notif_chat_count: 0,
        notif_inbox: false,
        notif_inbox_count: 0,
        notif_market: false,
        notif_market_count: 0,
        notif_board: false,
        notif_board_count: 0,
      });
      this.setNotifications();
    } else {
      console.log(data.statusText);
    }
  };

  deleteNotificationFromDB(notification_type) {
    fetch('/api/notifications/remove', {
      method: 'POST',
      body: JSON.stringify({ notification_type: notification_type }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log('Notification removed!');
        } else if (res.statusText) {
          console.log(res.statusText);
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  notifPopupAnimation(notif) {
    // si une popup arrive alors qu'une autre est déjà présente, on queueing
    var popup = document.getElementsByClassName('notif-popup')[0];
    if (popup) {
      if (popup.getElementsByTagName('p')[0].innerHTML === notif.message) {
        // Si on vient de recevoir un même type de notif, pas besoin de l'affiche
        return; //on exit la func()
      } else {
        let audio = new Audio(require('../../src/assets/sounds/notification.mp3'));
        audio.volume = global.config.sound.volume;
        audio.play();
        popup.getElementsByTagName('p')[0].innerHTML = notif.message;
        // if (this.disappearingNotif) clearTimeout(this.disappearingNotif);
        this.disappearingNotif = setTimeout(() => {
          popup.style.opacity = '0';
          popup.classList.add('animate__fadeOut');
          setTimeout(() => {
            document.body.removeChild(popup);
          }, 1000);
        }, 3000);
      }
    } else {
      let audio = new Audio(require('../../src/assets/sounds/notification.mp3'));
      audio.volume = global.config.sound.volume;
      audio.play();
      var div = document.createElement('div');
      var para = document.createElement('p');
      var node = document.createTextNode(notif.message);
      para.appendChild(node);
      div.appendChild(para);
      div.classList.add('notif-popup');
      document.body.appendChild(div);
      div.style.opacity = '1';
      div.classList.add('animate__animated');
      div.classList.add('animate__fadeIn');
      this.disappearingNotif = setTimeout(() => {
        div.style.opacity = '0';
        div.classList.add('animate__fadeOut');
        setTimeout(() => {
          document.body.removeChild(div);
        }, 1000);
      }, 3000);
    }
  }

  checkMax(value, max) {
    if (value >= max) {
      return max + '+';
    } else {
      return value;
    }
  }

  setNotifications() {
    var notifs = this.state.data.notifications;
    var url = window.location.href;
    var lastPart = url.substr(url.lastIndexOf('/') + 1);
    notifs.forEach((notif) => {
      var diff = Math.abs(new Date() - new Date(notif.date));
      var secondesEcoulees = Math.floor(diff / 1000);
      if (
        secondesEcoulees <= 5 &&
        notif.seen === false &&
        notif.nav_category !== 'chat' &&
        notif.nav_category !== 'forum'
      ) {
        this.notifPopupAnimation(notif);
      }
      switch (notif.nav_category) {
        case 'forum':
          if (lastPart !== 'forum') {
            this.setState({
              notif_forum: true,
              notif_forum_count: this.checkMax(this.state.notif_forum_count + 1, 10),
            });
          }
          break;
        case 'chat':
          if (lastPart !== 'chat') {
            this.setState({
              notif_chat: true,
              notif_chat_count: this.checkMax(this.state.notif_chat_count + 1, 10),
            });
          }
          // else {
          //   this.deleteNotificationFromDB('chat');
          // }
          break;
        case 'inbox':
          this.setState({
            notif_inbox: true,
            notif_inbox_count: this.checkMax(this.state.notif_inbox_count + 1, 10),
          });
          break;
        case 'market':
          if (lastPart !== 'market') {
            this.setState({
              notif_market: true,
              notif_market_count: this.checkMax(this.state.notif_market_count + 1, 10),
            });
          }
          break;
        case 'board':
          this.setState({
            notif_board: true,
            notif_board_count: this.checkMax(this.state.notif_board_count + 1, 30),
          });
          break;
        default:
          break;
      }
    });
  }

  removeNotification(type) {
    switch (type) {
      case 'forum':
        if (this.state.notif_forum) {
          this.setState({
            notif_forum: false,
            notif_forum_count: 0,
          });
        }
        // this.deleteNotificationFromDB(type);
        break;
      case 'chat':
        if (this.state.notif_chat) {
          this.setState({
            notif_chat: false,
            notif_chat_count: 0,
          });
        }
        // this.deleteNotificationFromDB(type);
        break;
      case 'market':
        if (this.state.notif_market) {
          this.setState({
            notif_market: false,
            notif_market_count: 0,
          });
        }
        this.deleteNotificationFromDB(type);
        break;

      default:
        break;
    }
  }

  updateLastVisit(page) {
    if (page === 'forum' || page === 'chat' || page === 'market') {
      fetch('/api/notifications/update-last-visit', {
        method: 'POST',
        body: JSON.stringify({ page: page }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log('Last Visit Page Updated!');
          } else if (res.statusText) {
            console.log(res.statusText);
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  // prevent too many sockets on /chat
  delayRedirect(event, location) {
    this.removeNotification(location.substring(1)); // on retire le / du début
    this.updateLastVisit(location.substring(1));
    event.preventDefault();
    if (!this._redirecting) {
      this._redirecting = true;
      const {
        history: { push },
      } = this.props;
      // push(location);
      push({
        pathname: location,
        state: { previousPage: 'loadProfilFromNavBar' },
      });
      setTimeout(() => (this._redirecting = false), 750); // next redirect is forbidden for 1 second (1000ms)
    }
  }

  updateVolume() {
    if (this.state.volume === 'on') {
      this.setState({
        volume: 'off',
      });
      global.config.sound.volume = 0;
    } else if (this.state.volume === 'off') {
      this.setState({
        volume: 'on',
      });
      global.config.sound.volume = 1;
    }
  }

  render() {
    return (
      <>
        <nav className="header-nav">
          <ul>
            <li>
              {this.state.notif_board ? (
                <span className="notif-pastille">{this.state.notif_board_count}</span>
              ) : (
                console.log()
              )}
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/board');
                }}
              >
                Board
              </button>
            </li>{' '}
            |
            <li>
              {this.state.notif_market ? (
                <span className="notif-pastille">{this.state.notif_market_count}</span>
              ) : (
                console.log()
              )}
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/market');
                }}
              >
                Market
              </button>
            </li>{' '}
            |
            <li>
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/horses');
                }}
              >
                Horses
              </button>
            </li>{' '}
            |
            <li>
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/army');
                }}
              >
                Army
              </button>
            </li>{' '}
            |
            <li>
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/technologies');
                }}
              >
                Technologies
              </button>
            </li>{' '}
            |
            <li>
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/buildings');
                }}
              >
                Buildings
              </button>
            </li>{' '}
            |
            <li>
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/profil');
                }}
              >
                Profile
              </button>
            </li>{' '}
            |
            <li>
              {this.state.notif_inbox ? (
                <span className="notif-pastille">{this.state.notif_inbox_count}</span>
              ) : (
                console.log()
              )}
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/inbox');
                }}
              >
                Inbox
              </button>
            </li>{' '}
            |
            <li>
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/population');
                }}
              >
                Population
              </button>
            </li>{' '}
            |
            <li>
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/lands');
                }}
              >
                Lands
              </button>
            </li>{' '}
            |
            <li>
              {this.state.notif_chat ? (
                <span className="notif-pastille">{this.state.notif_chat_count}</span>
              ) : (
                console.log()
              )}
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/chat');
                }}
              >
                Chat
              </button>
            </li>{' '}
            |
            <li>
              {this.state.notif_forum ? (
                <span className="notif-pastille">{this.state.notif_forum_count}</span>
              ) : (
                console.log()
              )}
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/forum');
                }}
              >
                Forum
              </button>
            </li>{' '}
            |
            <li>
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/help');
                }}
              >
                Help
              </button>
            </li>{' '}
            |
            <li>
              <button
                onClick={(e) => {
                  this.delayRedirect(e, '/logout');
                }}
              >
                Logout
              </button>
            </li>{' '}
            |
            <li>
              <button
                id="sound-btn"
                onClick={(e) => {
                  this.updateVolume();
                }}
              >
                <img src={require(`../../src/assets/images/volume_${this.state.volume}.png`)} alt="Sound Volume" />
              </button>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}

export default withRouter(Nav);
