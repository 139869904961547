import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ChatConsole extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      messages: [],
      muted_list: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const data = await fetch('/api/chat/console');
    const elements = await data.json();
    this.setState({
      messages: elements[0],
      muted_list: elements[1],
    });
    if (elements.length > 0) this.updateDocument();
  };

  render() {
    return (
      <div className="chat-console-component">
        <h1>Chat History</h1>

        {this.state.messages.length > 0 ? (
          <section className="history-messages"></section>
        ) : (
          <section className="history-messages">
            <p>/clear of the chat history made by admin</p>
          </section>
        )}

        {this.state.muted_list && this.state.muted_list.length !== 0 ? (
          <section className="muted-list">
            <h3>Muted Users</h3>
            <ul></ul>
          </section>
        ) : (
          <section className="muted-list">
            <h3>Muted Users</h3>
            <p>No muted user</p>
          </section>
        )}

        <Link to="/chat" id="backBtn" title="Back">
          <span role="img" aria-label="Back">
            ⬅️
          </span>
        </Link>
        <img
          id="maxClearImg"
          title="Delete Chat History"
          onClick={this.maxClear}
          src={require('../assets/images/chat/max-clear.png')}
          alt="Clear Historique"
        />
      </div>
    );
  }

  updateDocument() {
    this.state.messages.forEach((message) => {
      var messagesList = document.getElementsByClassName('history-messages')[0];
      var messageToAdd = document.createElement('p');
      messageToAdd.innerHTML =
        '<span id="time">' +
        message.time +
        '</span> - <span id="author" class="' +
        message.rang +
        '">' +
        message.author +
        '</span> <span class="' +
        message.command +
        '">' +
        message.message +
        '</span>';
      messagesList.appendChild(messageToAdd);
    });

    this.state.muted_list.forEach((user) => {
      //console.log(user);
      var list = document.getElementsByClassName('muted-list')[0].getElementsByTagName('ul')[0];
      var mutedUser = document.createElement('li');

      mutedUser.id = user.username;
      mutedUser.innerHTML =
        '<img id="mike" src="' + require(`../assets/images/chat/mute2.png`) + '" alt="Démuter" />' + user.username;
      const self = this;
      mutedUser.addEventListener('click', this.unmutePlayer(user.username, self));

      list.appendChild(mutedUser);
    });
  }

  maxClear() {
    fetch('/api/chat/console/clear').then((res) => {
      if (res.status === 200) {
        // Status admin: /console/clear autorisé
        var allMessages = document.getElementsByClassName('history-messages')[0].getElementsByTagName('p');
        var box = document.getElementsByClassName('history-messages')[0];
        for (var i = allMessages.length; i > 0; i--) {
          var element = allMessages[i - 1];
          box.removeChild(element);
        }
        var messageToAdd = document.createElement('p');
        messageToAdd.innerHTML = '/clear of the chat history made by admin';
        box.appendChild(messageToAdd);
      }
    });
  }

  unmutePlayer(pseudo, self) {
    return function () {
      fetch('/api/chat/mute-player', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userToMute: pseudo.toLowerCase() }),
      }).then((res) => {
        if (res.status === 200) {
          for (var i = self.state.muted_list.length - 1; i >= 0; i--) {
            if (self.state.muted_list[i].username === pseudo) {
              self.state.muted_list.splice([i], 1);
            }
          }
          self.setState({
            muted_list: self.state.muted_list,
          });
          self.saveCommandMessage(pseudo + ' a été démuté', 'mute-command');
        }
      });
    };
  }

  // Save Message to DB
  saveCommandMessage(message, command) {
    fetch('/api/chat/save-message', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ message: message, command: command }),
    });
  }
}
