import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ForgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: '',
      success: '',
      pass_id_to_activate: props.match.params.id,
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({
      success: '',
      error: '',
    });
    fetch('/api/auth/submit-forgot', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            success: 'Please check your mails.',
          });
        } else if (res.statusText) {
          this.setState({
            error: res.statusText,
          });
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  componentDidMount() {
    if (this.state.pass_id_to_activate) {
      const fetchRequest = '/api/auth/activate/' + this.state.pass_id_to_activate;

      fetch(fetchRequest, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          this.setState({
            success: 'Password activated.',
          });
        } else if (res.status === 400) {
          this.setState({
            success: '',
            error: res.statusText,
          });
        }
      });
    }
  }

  render() {
    const isError = this.state.error;
    const isSuccess = this.state.success;

    return (
      <div className="forgot-pass-component">
        <h3>Forgot Password</h3>

        {isError ? (
          <section className="errors">
            <p>{this.state.error}</p>
          </section>
        ) : (
          <p></p>
        )}

        {isSuccess && !isError ? (
          <section className="success">
            <p>{this.state.success}</p>
          </section>
        ) : (
          <p></p>
        )}

        <form onSubmit={this.onSubmit}>
          <input
            type="email"
            name="mail"
            placeholder="eMail Address"
            defaultValue={this.state.email}
            onChange={this.handleInputChange}
            required
          />
          <input type="submit" className="buttonCSS3" value="Send a new password" />
        </form>

        <p className="retour">
          <Link to="/">Back</Link>
        </p>
      </div>
    );
  }
}
