import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MessageInboxAnswer from './MessageInboxAnswer';

export default class MessageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      page: props.match.params.page,
    };
  }

  componentDidMount() {
    var page;
    if (this.state.page) {
      if (RegExp('^[0-9]*$').test(this.state.page)) {
        // si /inbox/view/:id/3, affiche page de answers (3 étant le n°page)
        page = parseInt(this.state.page);
      } else if (RegExp('[A-z]*$').test(this.state.page)) {
        // si /inbox/view/:id/lettres+nombre (error) redirige /error
        this.props.history.push('/error');
      }
    } else {
      page = 0;
    }
    this.fetchMessage(this.state.id, page);
  }

  fetchMessage = async (id, page) => {
    const url = '/api/inbox/view/' + id + '/' + page;
    console.log('url=', url);
    const data = await fetch(url);
    if (data.status === 200) {
      const message = await data.json();
      //console.log(message);
      this.setState({
        message: message,
        page: page,
      });
    } else {
      console.log(data.statusText);
    }
  };

  loadNextAnswers = () => {
    if (this.state.message.answers.length >= 10) {
      const page_to_fetch = this.state.page + 1;
      this.fetchMessage(this.state.id, page_to_fetch);
      window.scrollTo(0, 0);
    }
  };

  loadPreviousAnswers = () => {
    const page = this.state.page;
    if (page > 0) {
      this.fetchMessage(this.state.id, page - 1);
      window.scrollTo(0, 0);
    }
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    if (value === '') {
      const post = document.getElementById('answer');
      post.placeholder = 'Your Answer';
    }
    this.setState({
      [name]: value,
      new_answer_cache: value,
    });
  };

  componentDidUpdate() {
    this.initTextareas();
  }

  initTextareas() {
    var textAreas = document.querySelectorAll('textarea');
    textAreas.forEach((element) => {
      element.style.height = '';
      element.style.height = element.scrollHeight + 'px';
      element.parentElement.style.height = element.scrollHeight + 60 + 'px';
    });
  }

  adjustRang(rank) {
    if (rank === 2) {
      return '[admin]';
    } else if (rank === 1) {
      return '[mod]';
    } else {
      return '';
    }
  }

  submitAnswer = (event) => {
    event.preventDefault();
    fetch('/api/inbox/submit-answer', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.props.history.push('/inbox/view/' + this.state.id + '/' + this.state.page);
        } else if (res.statusText) {
          const post = document.getElementById('answer');
          this.setState({
            new_answer_cache: post.value,
          });
          post.value = '';
          post.placeholder = res.statusText;
          post.blur();
          this.initTextareas();
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  detectClick = () => {
    if (this.state.new_answer_cache !== undefined) {
      const post = document.getElementById('new-message-form').getElementsByTagName('textarea')[0];
      post.value = this.state.new_answer_cache;
      this.initTextareas();
    }
  };

  render() {
    var self = this;
    return (
      <>
        {this.state.message ? (
          <>
            <div id="arrow-title">
              <Link to="/inbox" id="backBtn-topic">
                <span role="img" aria-label="Retour">
                  ⬅️
                </span>
              </Link>
              <div id="title-topic-container">
                <h1>{this.state.message.subject}</h1>
              </div>
            </div>

            <div className="message-viewer-component">
              {this.state.message.answers.map((answer) => {
                return <MessageInboxAnswer key={answer._id} answer={answer} />;
              })}

              {(function () {
                if (self.state.message.hasMore && self.state.page > 0) {
                  // next et previous
                  return (
                    <div id="answers-nav-buttons">
                      <button id="next-btn-answers" onClick={self.loadNextAnswers}>
                        <span role="img" aria-label="Next">
                          ➡️
                        </span>
                      </button>
                      <button id="previous-btn-answers" onClick={self.loadPreviousAnswers}>
                        <span role="img" aria-label="Back">
                          ⬅️
                        </span>
                      </button>
                    </div>
                  );
                } else if (self.state.message.hasMore && self.state.page <= 0) {
                  // que next
                  return (
                    <div id="answers-nav-buttons">
                      <button id="next-btn-answers" onClick={self.loadNextAnswers}>
                        <span role="img" aria-label="Next">
                          ➡️
                        </span>
                      </button>
                    </div>
                  );
                } else if (!self.state.message.hasMore && self.state.page > 0) {
                  // que previous
                  return (
                    <div id="answers-nav-buttons">
                      <button id="previous-btn-answers" onClick={self.loadPreviousAnswers}>
                        <span role="img" aria-label="Back">
                          ⬅️
                        </span>
                      </button>
                    </div>
                  );
                } else {
                  return <span></span>;
                }
              })()}

              <div id="new-message-form">
                <form onSubmit={this.submitAnswer}>
                  <textarea
                    type="text"
                    name="answer"
                    id="answer"
                    placeholder="Your Answer"
                    maxLength="800"
                    onChange={this.handleInputChange}
                    onClick={this.detectClick}
                    onFocus={this.detectClick}
                    required
                  />
                  {/* <input type="submit" value="Envoyer" /> */}
                  <button id="btn-answer-message" onClick={() => this.submitAnswer}>
                    Answer for 100 <img src={require(`../assets/images/ressources/gold-bars.png`)} alt="Gold Price" />
                  </button>
                </form>
              </div>
            </div>
          </>
        ) : (
          <span></span>
        )}
      </>
    );
  }
}
