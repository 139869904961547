import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Connexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: '',
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    fetch('/api/auth/submit-connexion', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          let audio = new Audio(require('../../src/assets/sounds/login.mp3'));
          audio.play();
          this.props.history.push({
            pathname: '/board',
            // state: { previousPage: 'loadProfilFromNavBar' },
          });
        } else if (res.statusText) {
          this.setState({
            error: res.statusText,
          });
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
    this.setState({
      password: '',
    });
    document.getElementById('password').value = '';
    //document.getElementById('email').value = '';
  };

  render() {
    const isError = this.state.error;
    var isDisconnect;

    if (typeof this.props.history.location.state !== 'undefined') {
      isDisconnect = this.props.history.location.state.detail;
    }

    return (
      <div className="home-component">
        <h1>dokime</h1>
        <h2>A strategy game. Become the most powerful.</h2>

        {isDisconnect && !isError ? (
          <section className="errors">
            <p>{isDisconnect}</p>
          </section>
        ) : (
          <span></span>
        )}

        {isError ? (
          <section className="errors">
            <p>{this.state.error}</p>
          </section>
        ) : (
          <span></span>
        )}

        <div className="connexion-inscription-container">
          <form onSubmit={this.onSubmit}>
            <input
              type="email"
              name="mail"
              id="email"
              placeholder="eMail Address"
              defaultValue={this.state.email}
              onChange={this.handleInputChange}
              required
            />
            <input
              type="password"
              name="mdp"
              id="password"
              placeholder="Password"
              defaultValue={this.state.password}
              onChange={this.handleInputChange}
              required
            />
            <input type="submit" value="LOGIN" />
          </form>
        </div>
        <Link to="/forgot_pass" className="other-link">
          Forgot Password →
        </Link>
        <Link to="/inscription" className="other-link">
          Sign-Up →
        </Link>

        <ul>
          <li>
            <Link to="/rules">Rules</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/credits">Credits</Link>
          </li>
        </ul>
      </div>
    );
  }
}
