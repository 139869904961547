import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Inscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      username: '',
      error: '',
      success: '',
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    fetch('/api/auth/submit-signup', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            error: '',
            success: 'Your password has been sent to you by mail. Welcome 🥳 ',
          });
          document.getElementById('mail').value = '';
          document.getElementById('username').value = '';
        } else if (res.statusText) {
          this.setState({
            error: res.statusText,
            success: '',
          });
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const isError = this.state.error;
    const isSuccess = this.state.success;
    return (
      <div className="home-component">
        <h1>dokime</h1>
        <h2>A strategy game. Become the most powerful.</h2>

        {isError && !isSuccess ? (
          <section className="errors">
            <p>{this.state.error}</p>
          </section>
        ) : (
          <span></span>
        )}

        {isSuccess && !isError ? (
          <section className="success">
            <p>{this.state.success}</p>
          </section>
        ) : (
          <span></span>
        )}

        <div className="connexion-inscription-container">
          <form onSubmit={this.onSubmit}>
            <input
              type="text"
              name="pseudo"
              id="username"
              placeholder="Username"
              defaultValue={this.state.username}
              onChange={this.handleInputChange}
              required
            />
            <input
              type="email"
              name="mail"
              id="mail"
              placeholder="eMail Address"
              defaultValue={this.state.email}
              onChange={this.handleInputChange}
              required
            />
            <input type="submit" value="SIGN-UP" />
          </form>
        </div>
        {/* <Link to='/forgot_pass' className="other-link">Password Forgotten →</Link> */}
        <Link to="/connexion" className="other-link">
          Login →
        </Link>

        <ul>
          <li>
            <Link to="/rules">Rules</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/credits">Credits</Link>
          </li>
        </ul>
      </div>
    );
  }
}
