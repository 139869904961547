import React from 'react';
import { Component } from 'react';

import BankTradeChart from './BankTradeChart';

export default class Market extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      isInvestingStockIndex: false,
      isBuying: false,
      isBuyingMarket: false,
      minuts: 0,
      seconds: 0,
      selected_ressource: 'iron',
      quantity: '100',
      price: 0,
      error: '',
      errorMarket: '',
      market: {
        weapons: {},
        landsOwned: [],
        market_sells: [],
        estate_for_sell: [],
      },
    };
  }

  componentDidMount() {
    this.fetchData(this.state.page);
  }

  componentWillUnmount() {
    clearTimeout(this.estateAnimationTimeout_pt1);
    clearTimeout(this.estateAnimationTimeout_pt2);
    clearTimeout(this.makeErrorDisappear_pt1);
    clearTimeout(this.makeErrorDisappear_pt2);
    clearTimeout(this.buyAnimationTimeout_pt1);
    clearTimeout(this.buyAnimationTimeout_pt2);
    clearTimeout(this.deleteAnimationTimeout_pt1);
    clearTimeout(this.deleteAnimationTimeout_pt2);
    clearTimeout(this.deleteAnimationTimeout_pt3);
    clearTimeout(this.makeMarketErrorDisappear_pt1);
    clearTimeout(this.makeMarketErrorDisappear_pt2);
    this.updateLastVisit();
  }

  fetchData = async (page) => {
    const url = '/api/market/get/' + page;
    const data = await fetch(url);
    if (data.status === 200) {
      var market = await data.json();
      // console.log(market);
      this.setState({
        market: market,
      });
    } else {
      console.log(data.statusText);
    }
  };

  updateLastVisit() {
    fetch('/api/notifications/update-last-visit', {
      method: 'POST',
      body: JSON.stringify({ page: 'market' }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log('Last Visit Page Updated!');
        } else if (res.statusText) {
          console.log(res.statusText);
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  returnItem = (item_name) => {
    var toSend;
    switch (item_name) {
      case 'knife':
        //toSend = item_name.charAt(0).toLowerCase() + item_name.slice(1);
        toSend = item_name;
        break;
      case 'gun':
        toSend = item_name;
        break;
      case 'rifle':
        toSend = item_name;
        break;
      case 'missile':
        toSend = item_name;
        break;
      case 'sniper':
        toSend = item_name;
        break;
      case 'rpg':
        // toSend = item_name.toLowerCase();
        toSend = item_name;
        break;
      case 'oil':
        toSend = 'oil-baril';
        break;
      case 'iron':
        toSend = 'iron-cart';
        break;
      case 'energy':
        toSend = 'energy-bottle';
        break;
      case 'water':
        toSend = 'water-bottle';
        break;
      default:
        toSend = 'country';
        break;
    }
    return toSend;
  };
  // Format displaying numbers
  formatNumber(number) {
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      const new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  checkIfCountry = (value, nom) => {
    if (value === 0) {
      return nom;
    } else return this.formatNumber(value);
  };

  handleInputChangeRessource = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
    if (value === 'iron' || value === 'oil' || value === 'water' || value === 'energy') {
      this.setState({ quantity: '100' });
    } else if (
      value === 'Knife' ||
      value === 'Gun' ||
      value === 'Rifle' ||
      value === 'RPG' ||
      value === 'Sniper' ||
      value === 'Missile'
    ) {
      this.setState({ quantity: '10' });
    } else {
      this.setState({ quantity: '1' });
    }
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  submitSell = (event) => {
    event.preventDefault();

    fetch('/api/market/submit-sell', {
      method: 'POST',
      body: JSON.stringify({
        selected_ressource: this.state.selected_ressource,
        quantity: this.state.quantity,
        price: this.state.price,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log('Item Set For Sell! Yayks');
          this.fetchData(0);
        } else if (res.statusText) {
          this.setState({
            errorMarket: res.statusText,
          });
          document.getElementById('market-error').style.opacity = '1';
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });

    this.makeMarketErrorDisappear_pt1 = setTimeout(() => {
      document.getElementById('market-error').style.opacity = '0';
      this.makeMarketErrorDisappear_pt2 = setTimeout(() => {
        this.setState({
          isBuying: false,
          marketError: '',
        });
      }, 500);
    }, 2000);
  };

  deleteAnimation = (id) => {
    // var self = this;
    var tr = document.getElementById(id);
    // var tbody = document.getElementById('market-sells-tbody');
    var div = document.createElement('div');
    var para = document.createElement('p');
    var node = document.createTextNode('Sell Removed!');
    para.appendChild(node);
    div.appendChild(para);

    div.classList.add('deleted-market-sell');

    tr.innerHTML = '';
    tr.appendChild(div);

    div.classList.add('animate__animated');
    div.classList.add('animate__fadeIn');

    this.deleteAnimationTimeout_pt1 = setTimeout(() => {
      div.style.opacity = 0;
      this.deleteAnimationTimeout_pt2 = setTimeout(() => {
        tr.removeChild(div);
        this.fetchData(this.state.page);
      }, 100);
    }, 1000);
  };

  buyAnimation = (id) => {
    var tr = document.getElementById(id);
    // var tbody = document.getElementById('market-sells-tbody');
    var div = document.createElement('div');
    var para = document.createElement('p');
    var node = document.createTextNode('Sell Bought!');
    para.appendChild(node);
    div.appendChild(para);

    div.classList.add('bought-market-sell');

    tr.innerHTML = '';
    tr.appendChild(div);

    div.classList.add('animate__animated');
    div.classList.add('animate__fadeIn');

    this.buyAnimationTimeout_pt1 = setTimeout(() => {
      div.style.opacity = 0;
      this.buyAnimationTimeout_pt2 = setTimeout(() => {
        tr.removeChild(div);
        this.fetchData(this.state.page);
      }, 100);
    }, 1000);
  };

  estateAnimation = (id) => {
    var tr = document.getElementById(id);
    // var tbody = document.getElementById('real-estate-tbody');
    var div = document.createElement('div');
    var para = document.createElement('p');
    var node = document.createTextNode('Real Estate Bought!');
    para.appendChild(node);
    div.appendChild(para);

    div.classList.add('success-real-estate');

    tr.innerHTML = '';
    tr.appendChild(div);

    div.classList.add('animate__animated');
    div.classList.add('animate__fadeIn');

    this.estateAnimationTimeout_pt1 = setTimeout(() => {
      div.style.opacity = 0;
      this.estateAnimationTimeout_pt2 = setTimeout(() => {
        tr.removeChild(div);
        this.fetchData(this.state.page);
      }, 100);
    }, 1000);
  };

  buyEstate = (estate_id) => {
    if (this.state.isBuying === false) {
      this.setState({
        isBuying: true,
      });
      //console.log('estate_id=', estate_id);
      fetch('/api/market/buy-estate', {
        method: 'POST',
        body: JSON.stringify({ estate_id: estate_id }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.estateAnimation(estate_id);
          } else if (res.statusText) {
            this.setState({
              error: res.statusText,
            });
            document.getElementById('estate-error').style.opacity = '1';
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });

      this.makeErrorDisappear_pt1 = setTimeout(() => {
        document.getElementById('estate-error').style.opacity = '0';
        this.makeErrorDisappear_pt2 = setTimeout(() => {
          this.setState({
            isBuying: false,
            error: '',
          });
        }, 500);
      }, 2000);
    } else {
      console.log('Stop Spam');
    }
  };

  buyMarketSell = (sell_id) => {
    if (this.state.isBuyingMarket === false) {
      this.setState({
        isBuying: true,
      });
      fetch('/api/market/buy-sell', {
        method: 'POST',
        body: JSON.stringify({ sell_id: sell_id }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log('Sell bought!');
            this.buyAnimation(sell_id);
          } else if (res.statusText) {
            this.setState({
              errorMarket: res.statusText,
            });
            document.getElementById('market-error').style.opacity = '1';
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });

      this.makeMarketErrorDisappear_pt1 = setTimeout(() => {
        document.getElementById('market-error').style.opacity = '0';
        this.makeMarketErrorDisappear_pt2 = setTimeout(() => {
          this.setState({
            isBuying: false,
            marketError: '',
          });
        }, 500);
      }, 2000);
    } else {
      console.log('Stop Spam');
    }
  };

  deleteMarketSell = (sell_id) => {
    fetch('/api/market/delete-sell', {
      method: 'POST',
      body: JSON.stringify({ sell_id: sell_id }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log('Sell deleted!');
          this.deleteAnimation(sell_id);
        } else if (res.statusText) {
          console.log(res.statusText);
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  loadNextSells = () => {
    if (this.state.market.hasMore) {
      //console.log('this.state.page+1=', this.state.page + 1);
      const page_to_fetch = this.state.page + 1;
      this.setState({
        page: page_to_fetch,
      });
      this.fetchData(page_to_fetch);
    }
  };

  loadPreviousSells = () => {
    const page = this.state.page;
    //console.log('this.state.page-1=', this.state.page - 1);
    const page_to_fetch = this.state.page - 1;
    if (page > 0) {
      this.setState({
        page: page_to_fetch,
      });
      this.fetchData(page_to_fetch);
    }
  };

  render() {
    var self = this;
    var listOfLands;
    var listOfWeapons;
    if (this.state.market.landsOwned.length >= 1) {
      listOfLands = this.state.market.landsOwned.map((land) => (
        <option key={land._id} value={land.name}>
          {land.name}
        </option>
      ));
    }
    if (this.state.market.weapons.length >= 1) {
      listOfWeapons = this.state.market.weapons.map((weapon) => (
        <option key={weapon.name} value={weapon.name}>
          {weapon.name} [{weapon.quantity}]
        </option>
      ));
    }

    return (
      <>
        <div id="title-market">
          <h1>
            <span role="img" aria-label="Emoji Market">
              💶
            </span>{' '}
            Market
          </h1>
        </div>

        <div className="market-component animate__animated animate__fadeIn">
          <div id="real-estate">
            <div className="real-estate-title">
              <span id="estate-error">{this.state.error}</span>
              <h2>
                <img src={require(`../../src/assets/images/market/real_estate/estate-agent.png`)} alt="Estate Agent" />
                Real Estate
              </h2>
            </div>
            <div className="line"></div>
            <table>
              <colgroup>
                <col width="280px" />
                <col />
                <col />
                <col />
              </colgroup>

              <thead>
                <tr>
                  <th>Type</th>
                  <th>
                    Rent <img src={require('../../src/assets/images/market/gold-coins.png')} alt="Gold Coins" />
                  </th>
                  <th>
                    Price <img src={require('../../src/assets/images/market/gold-coins.png')} alt="Price Sign" />{' '}
                  </th>
                </tr>
              </thead>

              <tbody id="real-estate-tbody">
                {this.state.market.estate_for_sell.map((house) => {
                  return (
                    <tr key={house._id} id={house._id}>
                      <td className="type">
                        <img
                          src={require(`../../src/assets/images/market/real_estate/${house.house_type}.png`)}
                          alt=""
                        />
                      </td>
                      <td className="rent">{this.formatNumber(house.rent)}</td>
                      <td className="price">
                        <span className="price-text">{this.formatNumber(house.price)}</span>
                        <button onClick={() => this.buyEstate(house._id)}>
                          <img
                            src={require(`../../src/assets/images/market/real_estate/buy-estate.png`)}
                            alt="Buy Estate"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {this.state.market.estate_for_sell.length <= 0 ? (
              <p className="no-real-estate-loaded">No Real Estate For Sell</p>
            ) : (
              <span></span>
            )}
          </div>

          <BankTradeChart />

          <div id="market-sells">
            <div className="market-sells-title">
              <span id="market-error">{this.state.errorMarket}</span>
              <h2>
                <img src={require(`../../src/assets/images/market/market-trade.png`)} alt="Estate Agent" />
                Players' Sells
              </h2>
              {(function () {
                if (self.state.market.hasMore && self.state.page > 0) {
                  // next et previous
                  return (
                    <>
                      <button id="next-sells-btn" onClick={self.loadNextSells}>
                        <span role="img" aria-label="Next">
                          ➡️
                        </span>
                      </button>

                      <button id="previous-sells-btn" onClick={self.loadPreviousSells}>
                        <span role="img" aria-label="Back">
                          ⬅️
                        </span>
                      </button>
                    </>
                  );
                } else if (self.state.market.hasMore && self.state.page <= 0) {
                  // que next
                  return (
                    <button id="next-sells-btn" onClick={self.loadNextSells}>
                      <span role="img" aria-label="Next">
                        ➡️
                      </span>
                    </button>
                  );
                } else if (!self.state.market.hasMore && self.state.page > 0) {
                  // que previous
                  return (
                    <>
                      <button id="previous-sells-btn" onClick={self.loadPreviousSells}>
                        <span role="img" aria-label="Back">
                          ⬅️
                        </span>
                      </button>
                    </>
                  );
                } else {
                  return <span></span>;
                }
              })()}
            </div>
            <div className="line"></div>
            <table>
              <colgroup>
                <col width="280px" />
                <col />
                <col />
                <col />
              </colgroup>

              <thead>
                <tr>
                  <th>Type</th>
                  <th>Quantity</th>
                  <th>
                    Price <img src={require('../../src/assets/images/market/gold-coins.png')} alt="Price Sign" />{' '}
                  </th>
                </tr>
              </thead>

              <tbody id="market-sells-tbody">
                {this.state.market.market_sells.map((sell) => {
                  return (
                    <tr id={sell._id} key={sell._id}>
                      <td className="type">
                        {sell.seller_id === true ? (
                          <button className="delete" onClick={() => this.deleteMarketSell(sell._id)}>
                            <span role="img" aria-label="delete">
                              ❌
                            </span>
                          </button>
                        ) : (
                          console.log()
                        )}
                        <img
                          src={require(`../../src/assets/images/market/items/${this.returnItem(sell.item_type)}.png`)}
                          alt=""
                        />
                      </td>
                      <td className="quantity">{this.checkIfCountry(sell.quantity, sell.item_type)}</td>
                      <td className="price">
                        <span className="price-text">{this.formatNumber(sell.price)}</span>
                        {sell.seller_id === false ? (
                          <button className="buy" onClick={() => this.buyMarketSell(sell._id)}>
                            BUY
                          </button>
                        ) : (
                          <button className="buy" style={{ background: 'red' }}>
                            BUY
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {this.state.market.market_sells.length <= 0 ? (
              <p className="no-market-sells-loaded">No Player Sell Yet</p>
            ) : (
              <span></span>
            )}
          </div>

          <div id="sells-form-container">
            <form onSubmit={this.submitSell}>
              <select name="selected_ressource" id="ressource" onChange={this.handleInputChangeRessource} required>
                <optgroup label="&nbsp;Ressources"></optgroup>
                <option value="iron">Iron</option>
                <option value="oil">Oil</option>
                <option value="water">Water</option>
                <option value="energy">Energy</option>
                {this.state.market.landsOwned.length >= 1 ? <optgroup label="&nbsp;Lands"></optgroup> : console.log()}
                {listOfLands}
                {this.state.market.weapons.length >= 1 ? <optgroup label="&nbsp;Weapons"></optgroup> : console.log()}
                {listOfWeapons}
              </select>

              <select
                name="quantity"
                id="quantity"
                onChange={this.handleInputChange}
                value={this.state.quantity}
                required
              >
                {this.state.selected_ressource !== 'oil' &&
                this.state.selected_ressource !== 'energy' &&
                this.state.selected_ressource !== 'water' &&
                this.state.selected_ressource !== 'iron' &&
                this.state.selected_ressource !== 'Knife' &&
                this.state.selected_ressource !== 'Gun' &&
                this.state.selected_ressource !== 'Rifle' &&
                this.state.selected_ressource !== 'RPG' &&
                this.state.selected_ressource !== 'Missile' &&
                this.state.selected_ressource !== 'Sniper' ? (
                  <option value="1">1</option>
                ) : (
                  console.log()
                )}
                {this.state.selected_ressource === 'oil' ||
                this.state.selected_ressource === 'energy' ||
                this.state.selected_ressource === 'water' ||
                this.state.selected_ressource === 'iron' ? (
                  <>
                    <option value="100">{this.formatNumber(100)}</option>
                    <option value="1000">{this.formatNumber(1000)}</option>
                    <option value="10000">{this.formatNumber(10000)}</option>
                    <option value="100000">{this.formatNumber(100000)}</option>
                    <option value="1000000">{this.formatNumber(1000000)}</option>
                  </>
                ) : (
                  console.log()
                )}
                {this.state.selected_ressource === 'Knife' ||
                this.state.selected_ressource === 'Gun' ||
                this.state.selected_ressource === 'Rifle' ||
                this.state.selected_ressource === 'RPG' ||
                this.state.selected_ressource === 'Missile' ||
                this.state.selected_ressource === 'Sniper' ? (
                  <>
                    <option value="10">{this.formatNumber(10)}</option>
                    <option value="100">{this.formatNumber(100)}</option>
                    <option value="1000">{this.formatNumber(1000)}</option>
                  </>
                ) : (
                  console.log()
                )}
              </select>

              <input
                name="price"
                id="price"
                type="number"
                placeholder="Gold Price"
                min="1"
                max="100000000"
                onChange={this.handleInputChange}
                required
              />

              <input type="submit" value="Put for Sell" />
            </form>
          </div>
        </div>
      </>
    );
  }
}
