import React from 'react';
import { Component } from 'react';
import HelpContent from './HelpContent';

export default class Help extends Component {

  render() {
    return (
      <div className="about-pages-component">
        <HelpContent validateButton={false} props={this.props} />
      </div>
    );
  }
}
