import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';

export default class UpdateProfil extends Component {
  timeOut;
  constructor() {
    super();
    this.state = {
      username: '',
      biography: '',
      avatar: '',
      current_password: '',
      new_password: '',
      error: '',
      success: '',
    };
  }

  componentDidMount() {
    this.fetchItems();
    setTimeout(() => {
      // On attend que les state soient bien chargés et la bio affichée avant d'ajuster sa taille
      document.getElementById('biography-input').style.height = '';
      document.getElementById('biography-input').style.height =
        document.getElementById('biography-input').scrollHeight + 'px';
    }, 400);
  }

  componentWillUnmount = () => {
    if (this.makeMessageDisappear) {
      clearTimeout(this.makeMessageDisappear);
    }
    if (this.resetTitle) {
      clearTimeout(this.resetTitle);
    }
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  };

  fetchItems = async () => {
    const data = await fetch('/api/profil');
    const profil = await data.json();
    if (profil.biography === '') {
      profil.biography = String; // ainsi, placeholder affiché si bio empty
    }
    //console.log(profil);
    this.setState({
      username: profil.username,
      biography: profil.biography,
      avatar: profil.avatar,
    });
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
    if (event.target.type === 'textarea') {
      document.getElementById('biography-input').style.height = '';
      document.getElementById('biography-input').style.height =
        document.getElementById('biography-input').scrollHeight + 'px';
    }
  };

  updatePassword = (event) => {
    event.preventDefault();
    fetch('/api/profil/update-password', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            success: 'Password Updated!',
            error: '',
          });
          this.fadeAwayMessagesAfterDelay(1000); // plus long délai pour messages d'erreur
        } else if (res.statusText) {
          this.setState({
            error: res.statusText,
            success: '',
          });
          this.fadeAwayMessagesAfterDelay(); // plus long délai pour messages d'erreur
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });

    this.setState({
      current_password: '',
      new_password: '',
    });
    //On ajuste pour les sections error/success
    document.getElementsByTagName('h1')[0].style.paddingTop = '0';
    document.getElementsByTagName('h1')[0].style.marginTop = '10px';
  };

  deleteProfil = (event) => {
    event.preventDefault();
    var deleteConfirmation = window.confirm('Do you really want to delete your account ? :(');

    if (deleteConfirmation) {
      fetch('/api/profil/delete-profil', {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.props.history.push({
              pathname: '/connexion',
              state: { detail: 'Account deleted successfully.' },
            });
          } else if (res.statusText) {
            this.setState({
              error: res.statusText,
              current_password: '',
            });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  updateAvatar(actualAvatar) {
    var self = this;
    return function () {
      let numberOfAvatars = 13;
      if (actualAvatar >= numberOfAvatars) {
        actualAvatar = 1;
      } else {
        actualAvatar++;
      }

      // Ancienne solution, remplacée par l'actuel self.setState avatar:
      // var path = require('../assets/images/avatars/' + actualAvatar + '.png');
      // document.getElementById('updatable-avatar').src = path;
      self.setState({
        avatar: actualAvatar,
      });

      if (self.timeOut) {
        clearTimeout(self.timeOut);
      }

      self.timeOut = setTimeout(() => {
        fetch('/api/profil/update-avatar', {
          method: 'POST',
          body: JSON.stringify({ avatar: actualAvatar }),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((res) => {
            if (res.status === 200) {
              console.log('Avatar Updated!');
              document.getElementsByTagName('h1')[0].style.paddingTop = '0';
              document.getElementsByTagName('h1')[0].style.marginTop = '10px';
              self.setState({
                success: 'Avatar Updated!',
              });
              self.fadeAwayMessagesAfterDelay(1000);
            } else if (res.statusText) {
              console.log('Error Updating Avatar');
            } else {
              const error = new Error(res.error);
              throw error;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    };
  }

  updateBio = () => {
    // le = () => permet d'appeler this.state dans cette fonction
    var newBiography = document.getElementById('biography-input').value;

    fetch('/api/profil/update-bio', {
      method: 'POST',
      body: JSON.stringify({ biography: newBiography }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            success: 'Biography Updated!',
            error: '',
          });
        } else if (res.statusText) {
          console.log('Error Updating bio');
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });

    // On ajuste pour les sections error/success
    document.getElementsByTagName('h1')[0].style.paddingTop = '0';
    document.getElementsByTagName('h1')[0].style.marginTop = '10px';
    this.fadeAwayMessagesAfterDelay(1000);
  };

  blurBioOnEnter() {
    var key = window.event.keyCode;
    if (key === 13) {
      document.getElementById('biography-input').blur(); // updateBio called (voir HTML) onBlur='updateBio();'
    }
  }

  fadeAwayMessagesAfterDelay(delay) {
    if (!delay) {
      delay = 4400;
    }

    this.makeMessageDisappear = setTimeout(() => {
      // si l'utilisateur a crée plusieurs sections en updatant sa bio ou mdp trop rapidement
      var everySection = document.querySelectorAll('section');
      everySection.forEach((element) => {
        element.style.opacity = '0';
      });
    }, delay); // 4400

    this.resetTitle = setTimeout(() => {
      document.getElementsByTagName('h1')[0].style.paddingTop = '40px';
      document.getElementsByTagName('h1')[0].style.marginTop = '30px';
      this.setState({
        error: '',
        success: '',
      });
    }, delay + 600); // 5000
  }

  render() {
    return (
      <div className="profil-component">
        {this.state.error ? (
          <section className="errors">
            <p>{this.state.error}</p>
          </section>
        ) : (
          <span></span>
        )}

        {this.state.success ? (
          <section className="success">
            <p>{this.state.success}</p>
          </section>
        ) : (
          <span></span>
        )}

        <h1>Profile</h1>
        {this.state.avatar ? (
          <img
            id="updatable-avatar"
            src={require(`../assets/images/avatars/${this.state.avatar}.png`)}
            alt="Avatar"
            onClick={this.updateAvatar(`${this.state.avatar}`)}
          />
        ) : (
          <span></span>
        )}

        <p className="username">{this.state.username}</p>

        {this.state.biography ? (
          <textarea
            id="biography-input"
            placeholder="Update your biography!"
            maxLength="150"
            onChange={this.handleInputChange}
            onKeyPress={this.blurBioOnEnter}
            onBlur={this.updateBio}
            defaultValue={this.state.biography}
          ></textarea>
        ) : (
          <span></span>
        )}

        {this.state.error || this.state.success ? (
          <span></span>
        ) : (
          <Link to={{ pathname: '/profil', state: { previousPage: 'loadProfilFromNavBar' } }} className="arrow">
            <span role="img" aria-label="Retour">
              ⬅️
            </span>
          </Link>
        )}

        <form onSubmit={this.updatePassword}>
          <input
            type="password"
            name="new_password"
            placeholder="New Password"
            value={this.state.new_password}
            onChange={this.handleInputChange}
            required
          />
          <input
            type="password"
            name="current_password"
            placeholder="Current Password"
            value={this.state.current_password}
            onChange={this.handleInputChange}
            required
          />

          <button className="password-btn" type="submit">
            Update Password
          </button>
          <br />
          <button className="delete-btn" onClick={this.deleteProfil}>
            Delete my account :(
          </button>
          <br />
        </form>
      </div>
    );
  }
}
