import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default class MessageWrite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      message: '',
      error: '',
      success: '',
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    fetch('/api/inbox/submit-message', {
      method: 'POST',
      body: JSON.stringify({
        subject: this.state.subject,
        message: this.state.message,
        receiver_id: this.props.history.location.state.receiver_id,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            success: true,
          });
        } else if (res.statusText) {
          this.setState({
            error: res.statusText,
          });
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    var receiver;
    const isError = this.state.error;
    if (typeof this.props.history.location.state !== 'undefined') {
      receiver = this.props.history.location.state.receiver;
      //receiver_id: this.props.history.location.state.receiver_id;
    } else {
      return <Redirect to="/error" />;
    }
    return (
      <div className="message-write">
        {this.state.success ? (
          <>
            <button className="arrow-back" onClick={() => this.props.history.goBack()}>
              <span role="img" aria-label="Retour">
                ⬅️
              </span>
            </button>
            <h1 id="message-sent">
              Message Sent{' '}
              <span role="img" aria-label="Sent">
                ✅
              </span>
            </h1>
          </>
        ) : (
          <>
            <h1>Message to {receiver}</h1>

            <button className="arrow-back" onClick={() => this.props.history.goBack()}>
              <span role="img" aria-label="Retour">
                ⬅️
              </span>
            </button>

            {isError ? (
              <section className="errors">
                <p>{this.state.error}</p>
              </section>
            ) : (
              <span></span>
            )}

            <form onSubmit={this.onSubmit}>
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder="Subject"
                maxLength="30"
                defaultValue={this.state.subject}
                onChange={this.handleInputChange}
                required
              />
              <textarea
                type="text"
                name="message"
                id="message"
                placeholder="Message"
                maxLength="800"
                defaultValue={this.state.message}
                onChange={this.handleInputChange}
                required
              />

              <button id="send-message-btn" onClick={() => this.onSubmit}>
                Send for 500 <img src={require(`../assets/images/ressources/gold-bars.png`)} alt="Gold Price" />
              </button>
            </form>
          </>
        )}
      </div>
    );
  }
}
