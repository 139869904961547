import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ForumAnswer extends Component {
  constructor(props) {
    super(props);
    this.canEdit = true;
    this.delayBetweenEdits = 5000;
    this.state = {
      topic_id: this.props.topic_id,
      answer: this.props.answer,
      viewerRang: this.props.viewerRang,
      rang: '',
      avatar: '',
      online_status: '',
      isOwnAnswer: '',
    };
  }

  componentDidMount() {
    this.fetchUserDetails(this.state.answer.id_author);
    this.initTextareas();
  }

  initTextareas() {
    var textAreas = document.querySelectorAll('textarea');
    textAreas.forEach((element) => {
      element.style.height = '';
      element.style.height = element.scrollHeight + 'px';
      element.parentElement.style.height = element.scrollHeight + 60 + 'px';
    });
  }

  fetchUserDetails = async (id) => {
    const url = '/api/profil/view/' + id;
    const data = await fetch(url);

    if (data.status === 200) {
      var userDetails = await data.json();
      // console.log('la réponse=', userDetails);
      this.setState({
        rang: userDetails.rang,
        avatar: userDetails.avatar,
        online_status: userDetails.online,
        isOwnAnswer: userDetails.isOwnProfil,
      });
    } else {
      // answer user details not found
      console.log('Answer user details not found');
    }
  };

  adjustRang(rank) {
    if (rank === 2) {
      return '[admin]';
    } else if (rank === 1) {
      return '[mod]';
    } else {
      return '';
    }
  }

  editPost(id) {
    if (this.canEdit) {
      const post = document.getElementById(id);
      var crayon = document.getElementById('btn-' + id);

      if (crayon.classList.contains('editing')) {
        crayon.classList.remove('editing');
        post.blur();
        post.readOnly = true;
        this.postEdit(id, post.value);
      } else {
        crayon.classList.add('editing');
        post.readOnly = false;
        post.focus();
        if (this.state.ancienne_value) {
          post.value = this.state.ancienne_value;
          this.initTextareas();
          post.style.color = 'black';
        } else if (this.state.ancienne_value === '') {
          post.value = '';
          this.initTextareas();
          post.style.color = 'black';
        }
      }
    } else {
      console.log('Wait before editing again.');
    }
  }

  postEdit(id, value) {
    if (this.canEdit) {
      this.canEdit = false;
      setTimeout(() => {
        this.canEdit = true;
      }, this.delayBetweenEdits);
      fetch('/api/forum/submit-answer-edit', {
        method: 'POST',
        body: JSON.stringify({
          topic_id: this.state.topic_id,
          answer_id: id,
          input: value,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json().then((res) => {
              this.setState({
                answer: res,
                ancienne_value: res.answer,
              });
            });
          } else if (res.statusText) {
            const post = document.getElementById(id);
            this.setState({
              ancienne_value: post.value,
            });
            post.value = 'Modification not saved. ' + res.statusText;
            post.style.color = 'red';
            this.initTextareas();
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.log('Wait before editing again.');
    }
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
    if (event.target.type === 'textarea') {
      // console.log("targetid= ", event.target.id);
      document.getElementById(event.target.id).style.height = '';
      document.getElementById(event.target.id).style.height =
        document.getElementById(event.target.id).scrollHeight + 'px';
      const answerBox = document.getElementById(event.target.id).parentElement;
      answerBox.style.height = document.getElementById(event.target.id).scrollHeight + 60 + 'px';
    }
  };

  deleteAnswer(id) {
    var deleteConfirmation = window.confirm('Do you really want to delete this answer ?');
    if (deleteConfirmation) {
      fetch('/api/forum/delete-answer', {
        method: 'POST',
        body: JSON.stringify({ topic_id: this.state.topic_id, answer_id: id }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            var post = document.getElementById('answer-' + id);
            post.style.display = 'none';
          } else if (res.statusText) {
            console.log("You don't have enough rights");
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  render() {
    return (
      <div className="answer" id={`answer-${this.state.answer._id}`}>
        {this.state.isOwnAnswer === true || this.state.viewerRang >= 1 ? (
          <div
            className="edit-post-btn"
            id={`btn-${this.state.answer._id}`}
            onClick={() => this.editPost(this.state.answer._id)}
          />
        ) : (
          <span></span>
        )}

        {this.state.viewerRang >= 1 ? (
          <div className="delete-post" onClick={() => this.deleteAnswer(this.state.answer._id)} />
        ) : (
          <span></span>
        )}

        <div className="answer-author-details">
          <p className="author-rank">
            <span className="rang-span" id={`rank-${this.state.rang}`}>
              {this.adjustRang(this.state.rang)}
            </span>
          </p>
          {this.state.avatar ? (
            <img src={require(`../assets/images/avatars/${this.state.avatar}.png`)} alt="Avatar" />
          ) : (
            <span></span>
          )}

          <Link to={`/profil/view/${this.state.answer.id_author}`}>
            <p className="author-username">
              <span className="status-span" id={`${this.state.online_status}`}></span>
              {this.state.answer.username_author}
            </p>
          </Link>
        </div>

        <textarea
          className="message-answer"
          id={this.state.answer._id}
          placeholder="Modify your answer!"
          maxLength="1800"
          readOnly={true}
          onChange={this.handleInputChange}
          defaultValue={this.state.answer.answer}
        ></textarea>

        {this.state.answer.edit_date !== undefined && this.state.answer.edit_author !== undefined ? (
          <p className="date-answer">
            Edited by {this.state.answer.edit_author} the {this.state.answer.edit_date}
          </p>
        ) : (
          <p className="date-answer">{this.state.answer.date}</p>
        )}
      </div>
    );
  }
}
