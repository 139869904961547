import React from 'react';
import { Component } from 'react';
import {Link} from 'react-router-dom';
import HelpContent from '../GameComponents/HelpContent';

export default class Rules extends Component {

  render() {
    return (
        <div className="about-pages-component">
          <HelpContent validateButton={false} props={this.props} />
          <br />
          <Link to="/" className="link-retour">
            Retour
          </Link>
        </div>
      );
  }
}
