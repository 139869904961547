import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ForumCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      content: '',
      error: '',
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    fetch('/api/forum/submit-create', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.props.history.push('/forum');
        } else if (res.statusText) {
          this.setState({
            error: res.statusText,
          });
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const isError = this.state.error;
    return (
      <div className="forum-create-topic">
        <h1>Create a Topic</h1>
        <Link to="/forum">
          <span role="img" aria-label="Back">
            ⬅️
          </span>
        </Link>

        {isError ? (
          <section className="errors">
            <p>{this.state.error}</p>
          </section>
        ) : (
          <span></span>
        )}

        <form onSubmit={this.onSubmit}>
          <input
            type="text"
            name="title"
            id="title"
            placeholder="Title"
            maxLength="40"
            defaultValue={this.state.title}
            onChange={this.handleInputChange}
            required
          />
          <textarea
            type="text"
            name="content"
            id="content"
            placeholder="Content"
            maxLength="1800"
            defaultValue={this.state.content}
            onChange={this.handleInputChange}
            required
          />

          <input type="submit" value="Post" />
        </form>
      </div>
    );
  }
}
