import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class MessageInboxAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: this.props.answer,
    };
  }

  componentDidMount() {
    this.fetchUserDetails(this.state.answer.author_id);
  }

  fetchUserDetails = async (id) => {
    const url = '/api/profil/view/' + id;
    const data = await fetch(url);

    if (data.status === 200) {
      var userDetails = await data.json();
      // console.log('la réponse=', userDetails);
      this.setState({
        rang: userDetails.rang,
        avatar: userDetails.avatar,
        online_status: userDetails.online,
      });
    } else {
      // answer user details not found
      console.log('Answer user details not found');
    }
  };

  adjustRang(rank) {
    if (rank === 2) {
      return '[admin]';
    } else if (rank === 1) {
      return '[mod]';
    } else {
      return '';
    }
  }

  render() {
    return (
      <div className="answer" id={`answer-${this.state.answer._id}`}>
        <div className="answer-author-details">
          <p className="author-rank">
            <span className="rang-span" id={`rank-${this.state.rang}`}>
              {this.adjustRang(this.state.rang)}
            </span>
          </p>
          {this.state.avatar ? (
            <img src={require(`../assets/images/avatars/${this.state.avatar}.png`)} alt="Avatar" />
          ) : (
            <span></span>
          )}

          <Link to={`/profil/view/${this.state.answer.author_id}`}>
            <p className="author-username">
              <span className="status-span" id={`${this.state.online_status}`}></span>
              {this.state.answer.author_username}
            </p>
          </Link>
        </div>

        <p className="message-text">{this.state.answer.content}</p>
        <p className="date-answer">{this.state.answer.date}</p>
      </div>
    );
  }
}
