import React from 'react';
import { Link } from 'react-router-dom';

function Credits() {
  return (
    <div className="about-pages-component">
      <h1>Credits</h1>
      <p>
        <a href="http://www.freepik.com" target="_blank" rel="noopener noreferrer" className="credits-link">
          Background Image by Vectorpocket/Freepik
        </a>
      </p>
      <p>
        <a href="http://www.zapsplat.com/" target="_blank" rel="noopener noreferrer" className="credits-link">
          Sound from Zapsplat.com
        </a>
      </p>
      <p>
        <a href="http://www.icons-land.com/" target="_blank" rel="noopener noreferrer" className="credits-link">
          Icons by Icons-Land
        </a>
      </p>
      <p>
        <a href="http://www.icons8.com/" target="_blank" rel="noopener noreferrer" className="credits-link">
          Icons by Icons8
        </a>
      </p>
      <p>
        <a href="http://www.flaticon.com/" target="_blank" rel="noopener noreferrer" className="credits-link">
          Icons by FlatIcon
        </a>
      </p>
      <br />
      <Link to="/" className="link-retour">
        Back
      </Link>
    </div>
  );
}

export default Credits;
