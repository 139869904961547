import React, { Component } from 'react';

export default class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inactivity: props.match.params.inactivity,
    };
  }

  componentDidMount() {
    fetch('/api/auth/logout', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.status === 200) {
        if (this.state.inactivity === 'inactivity') {
          this.props.history.push({
            pathname: '/connexion',
            state: { detail: 'Logged out for inactivity.' },
          });
        } else {
          this.props.history.push({
            pathname: '/connexion',
            state: { detail: 'Logged out.' },
          });
        }
      }
    });
  }

  render() {
    return <></>;
  }
}
