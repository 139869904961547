import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ForumAnswer from './ForumAnswer';

export default class ForumTopic extends Component {
  constructor(props) {
    super(props);
    this.canEdit = true;
    this.delayBetweenEdits = 5000;
    this.state = {
      id: props.match.params.id,
      page: props.match.params.page,
      title: '',
      message: '',
      author_id: '',
      author_username: '',
      author_avatar: 0,
      author_rang: 0,
      author_online_status: '',
      date: '',
      locked: false,
      important: false,
      answers: [],
      viewer_rang: 0,
      time_between_edits: 0,
    };
  }

  componentDidMount() {
    var page;
    if (this.state.page) {
      if (RegExp('^[0-9]*$').test(this.state.page)) {
        // si /topic/:id/3, affiche page de answers (3 étant le n°page)
        page = parseInt(this.state.page);
      } else if (RegExp('[A-z]*$').test(this.state.page)) {
        // si /topic/:id/lettres+nombre (error) redirige /error
        this.props.history.push('/error');
      }
    } else {
      page = 0;
    }
    this.fetchData(this.state.id, page);
  }

  componentDidUpdate() {
    this.initTextareas();
  }

  initTextareas() {
    var textAreas = document.querySelectorAll('textarea');
    textAreas.forEach((element) => {
      element.style.height = '';
      element.style.height = element.scrollHeight + 'px';
      element.parentElement.style.height = element.scrollHeight + 60 + 'px';
    });
  }

  fetchData = async (id, page) => {
    const url = '/api/forum/topic/' + id + '/' + page;
    //console.log('url=', url);
    const data = await fetch(url);

    if (data.status === 200) {
      var topic = await data.json();
      //console.log(topic);
      this.setState({
        title: topic.title,
        message: topic.message,
        author_id: topic.author_id,
        author_username: topic.author_username,
        author_biography: topic.author_biography,
        author_avatar: topic.author_avatar,
        author_rang: topic.author_rang,
        author_online_status: topic.author_online_status,
        edit_author: topic.edit_author,
        edit_date: topic.edit_date,
        date: topic.date,
        answers: topic.answers,
        locked: topic.locked,
        isEditable: topic.isEditable,
        important: topic.important,
        viewer_rang: topic.viewerRang,
        moreAnswers: topic.hasMore,
        page: page,
      });
    } else {
      // topic inexistant
      this.props.history.push('/error');
    }
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    if (value === '') {
      const post = document.getElementById('answer');
      post.placeholder = 'Your Answer';
    }
    // this.setState({
    //   [name]: value,
    //   new_answer_cache: value,
    // });
  };

  handleInputChangeAnswer = (event) => {
    const { value, name } = event.target;
    if (value === '') {
      const post = document.getElementById('answer');
      post.placeholder = 'Your Answer';
    }
    this.setState({
      [name]: value,
      new_answer_cache: value,
    });
  };

  submitAnswer = (event) => {
    event.preventDefault();
    fetch('/api/forum/submit-answer', {
      method: 'POST',
      body: JSON.stringify(this.state),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.props.history.push('/forum/topic/' + this.state.id + '/' + this.state.page);
        } else if (res.statusText) {
          const post = document.getElementById('answer');
          this.setState({
            new_answer_cache: post.value,
          });
          post.value = '';
          post.placeholder = res.statusText;
          post.blur();
          this.initTextareas();
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  lockTopic(id) {
    fetch('/api/forum/lock-topic', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ topic: id }),
    }).then((res) => {
      if (res.status === 200) {
        console.log(res.statusText);

        var locker = document.getElementsByClassName('locker')[0];

        if (res.statusText === 'Unlocked') {
          locker.addEventListener('mouseleave', function (event) {
            locker.id = 'topicUnlocker';
          });
        } else if (res.statusText === 'Locked') {
          locker.addEventListener('mouseleave', function (event) {
            locker.id = 'topicLocker';
          });
        }
      }
    });
  }

  adjustRang(rank) {
    if (rank === 2) {
      return '[admin]';
    } else if (rank === 1) {
      return '[mod]';
    } else {
      return '';
    }
  }

  deleteTopic(id) {
    var deleteConfirmation = window.confirm('Do you really want to delete this topic ?');
    if (deleteConfirmation) {
      fetch('/api/forum/delete-topic', {
        method: 'POST',
        body: JSON.stringify({ topic_id: id }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.props.history.push({
              pathname: '/forum',
              //,
              //state: {detail: 'Post supprimé avec succès.'}
            });
          } else if (res.statusText) {
            console.log("You don't have enough rights.");
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  starTopic(id) {
    fetch('/api/forum/star-topic', {
      method: 'POST',
      body: JSON.stringify({ topic_id: id }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          const new_state = !this.state.important;
          this.setState({
            important: new_state,
          });
        } else if (res.statusText) {
          console.log("You don't have enough rights.");
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  editPost(id) {
    if (this.canEdit) {
      const post = document.getElementById(id);
      var crayon = document.getElementById('btn-' + id);

      if (crayon.classList.contains('editing')) {
        crayon.classList.remove('editing');
        post.blur();
        post.readOnly = true;
        this.postEdit(id, post.value);
      } else {
        crayon.classList.add('editing');
        post.readOnly = false;
        post.focus();
        if (this.state.ancienne_value) {
          post.value = this.state.ancienne_value;
          post.style.color = 'black';
          this.initTextareas();
        } else if (this.state.ancienne_value === '') {
          post.value = '';
          post.style.color = 'black';
          this.initTextareas();
        }
      }
    } else {
      console.log('Wait before editing again.');
    }
  }

  postEdit(id, value) {
    if (this.canEdit) {
      this.canEdit = false;
      setTimeout(() => {
        this.canEdit = true;
      }, this.delayBetweenEdits);

      fetch('/api/forum/submit-topic-edit', {
        method: 'POST',
        body: JSON.stringify({ topic_id: id, input: value }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json().then((res) => {
              this.setState({
                message: res.message,
                edit_author: res.edit_author,
                edit_date: res.edit_date,
                ancienne_value: res.message,
              });
            });
          } else if (res.statusText) {
            const post = document.getElementById(id);
            this.setState({
              ancienne_value: post.value,
            });
            post.value = 'Modification not saved. ' + res.statusText;
            post.style.color = 'red';
            this.initTextareas();
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.log('Wait before editing again.');
    }
  }

  detectClick = () => {
    if (this.state.new_answer_cache !== undefined) {
      const post = document.getElementById('new-answer-form').getElementsByTagName('textarea')[0];
      post.value = this.state.new_answer_cache;
      this.initTextareas();
    }
  };

  loadNextAnswers = () => {
    if (this.state.moreAnswers) {
      //console.log('this.state.page+1=', this.state.page + 1);
      const page_to_fetch = this.state.page + 1;
      this.fetchData(this.state.id, page_to_fetch);
      window.scrollTo(0, 0);
    }
  };

  loadPreviousAnswers = () => {
    const page = this.state.page;
    //console.log('this.state.page-1=', this.state.page - 1);
    if (page > 0) {
      this.fetchData(this.state.id, page - 1);
      window.scrollTo(0, 0);
    }
  };

  render() {
    const self = this;

    return (
      <>
        <div id="arrow-title">
          <Link to="/forum" id="backBtn-topic">
            <span role="img" aria-label="Back">
              ⬅️
            </span>
          </Link>
          <div id="title-topic-container">
            {(function () {
              if (self.state.viewer_rang >= 2) {
                return (
                  <div
                    className="star-topic-admin"
                    id={`${self.state.important.toString()}`}
                    onClick={() => self.starTopic(self.state.id)}
                  />
                );
              } else if (self.state.viewer_rang < 2 && self.state.important) {
                return <div className="star-topic" id={`${self.state.important.toString()}`} />;
              }
            })()}

            <h1>{this.state.title}</h1>
          </div>
        </div>

        <div className="topic">
          {this.state.isEditable ? (
            <div
              className="edit-post-btn-topic"
              id={`btn-${this.state.id}`}
              onClick={() => this.editPost(this.state.id)}
            />
          ) : (
            <span></span>
          )}

          {this.state.viewer_rang >= 1 ? (
            <div className="delete-post" onClick={() => this.deleteTopic(this.state.id)} />
          ) : (
            <span></span>
          )}

          {this.state.viewer_rang <= 0 && this.state.locked ? (
            <div id="topicLocker-lambda" className="locker" />
          ) : (
            <span></span>
          )}

          {this.state.viewer_rang <= 0 && !this.state.locked ? (
            <div id="topicUnlocker-lambda" className="locker" />
          ) : (
            <span></span>
          )}

          {this.state.viewer_rang > 0 && this.state.locked ? (
            <div id="topicLocker" className="locker" onClick={() => this.lockTopic(this.state.id)} />
          ) : (
            <span></span>
          )}

          {this.state.viewer_rang > 0 && !this.state.locked ? (
            <div id="topicUnlocker" className="locker" onClick={() => this.lockTopic(this.state.id)} />
          ) : (
            <span></span>
          )}

          <div className="topic-author-details">
            <p className="author-rank">
              <span className="rang-span" id={`rank-${this.state.author_rang}`}>
                {this.adjustRang(this.state.author_rang)}
              </span>
            </p>
            {this.state.author_avatar ? (
              <img src={require(`../assets/images/avatars/${this.state.author_avatar}.png`)} alt="Avatar" />
            ) : (
              <span></span>
            )}

            <Link to={`/profil/view/${this.state.author_id}`}>
              <p className="author-username">
                <span className="status-span" id={`${this.state.author_online_status}`}></span>
                {this.state.author_username}
              </p>
            </Link>
          </div>

          <textarea
            className="message-topic"
            id={this.state.id}
            placeholder="Modify your Topic!"
            maxLength="1800"
            readOnly={true}
            onChange={this.handleInputChange}
            defaultValue={this.state.message}
          ></textarea>

          {this.state.edit_date !== undefined && this.state.edit_author !== undefined ? (
            <p id="date-topic">
              Edited by {this.state.edit_author} the {this.state.edit_date}
            </p>
          ) : (
            <p id="date-topic">{this.state.date}</p>
          )}
        </div>

        {/* maper que les 10 premieres answers et afficher bouton next et prev en bas */}
        {this.state.answers.map((element) => {
          return (
            <ForumAnswer
              key={element._id}
              answer={element}
              viewerRang={this.state.viewer_rang}
              topic_id={this.state.id}
            />
          );
        })}

        {(function () {
          if (self.state.moreAnswers && self.state.page > 0) {
            // next et previous
            return (
              <div id="answers-nav-buttons">
                <button id="next-btn-answers" onClick={self.loadNextAnswers}>
                  <span role="img" aria-label="Next">
                    ➡️
                  </span>
                </button>
                <button id="previous-btn-answers" onClick={self.loadPreviousAnswers}>
                  <span role="img" aria-label="Back">
                    ⬅️
                  </span>
                </button>
              </div>
            );
          } else if (self.state.moreAnswers && self.state.page <= 0) {
            // que next
            return (
              <div id="answers-nav-buttons">
                <button id="next-btn-answers" onClick={self.loadNextAnswers}>
                  <span role="img" aria-label="Next">
                    ➡️
                  </span>
                </button>
              </div>
            );
          } else if (!self.state.moreAnswers && self.state.page > 0) {
            // que previous
            return (
              <div id="answers-nav-buttons">
                <button id="previous-btn-answers" onClick={self.loadPreviousAnswers}>
                  <span role="img" aria-label="Back">
                    ⬅️
                  </span>
                </button>
              </div>
            );
          } else {
            return <span></span>;
          }
        })()}

        <div id="new-answer-form">
          <form onSubmit={this.submitAnswer}>
            <textarea
              type="text"
              name="answer"
              id="answer"
              placeholder="Your Answer"
              maxLength="1800"
              onChange={this.handleInputChangeAnswer}
              onClick={this.detectClick}
              onFocus={this.detectClick}
              required
            />
            <input type="submit" value="Post" />
          </form>
        </div>
      </>
    );
  }
}
