import React from 'react';
import { Component } from 'react';

export default class Productions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: 'Day',
      productions: this.props.productions,
      productionsToShow: {
        iron: 0,
        oil: 0,
        water: 0,
        energy: 0,
      },
    };
  }

  componentDidMount() {
    if (this.state.productions !== undefined) {
      this.setState({
        productionsToShow: this.state.productions.day
      });
    };
  }

  updateShowing() {
    switch (this.state.showing) {
      case 'Day':
        this.setState({
          showing: 'Hour',
          productionsToShow: this.state.productions.hour,
        });
        break;
      case 'Hour':
        this.setState({
          showing: 'Minut',
          productionsToShow: this.state.productions.minut,
        });
        break;
      case 'Minut':
        this.setState({
          showing: 'Second',
          productionsToShow: this.state.productions.second,
        });
        break;
      case 'Second':
        this.setState({
          showing: 'Day',
          productionsToShow: this.state.productions.day,
        });
        break;
      default:
        break;
    }
  }

  // Format displaying numbers
  formatNumber(number) {
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      const new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  render() {
    return (
      <div id="productions-component">
        <h3>Production <button onClick={() => this.updateShowing()}><p>Per {this.state.showing}</p></button></h3>
        <div className="ressources-bar">
          <div className="ressources-container">
            <div className="ressource-img-container">
              <img src={require(`../assets/images/ressources/ressourcesBar/iron-bars.png`)} alt="Iron Bars" />
            </div>
            <p>{this.formatNumber(this.state.productionsToShow.iron)}</p>
          </div>

          <div className="ressources-container">
            <div className="ressource-img-container">
              <img src={require(`../assets/images/ressources/ressourcesBar/oil-baril.png`)} alt="Oil Barils" />
            </div>
            <p>{this.formatNumber(this.state.productionsToShow.oil)}</p>
          </div>

          <div className="ressources-container">
            <div className="ressource-img-container">
              <img src={require(`../assets/images/ressources/ressourcesBar/water-drop.png`)} alt="Water Drops" />
            </div>
            <p>{this.formatNumber(this.state.productionsToShow.water)}</p>
          </div>

          <div className="ressources-container">
            <div className="ressource-img-container">
              <img src={require(`../assets/images/ressources/ressourcesBar/energy-thunder.png`)} alt="Energy Thunder" />
            </div>
            <p>{this.formatNumber(this.state.productionsToShow.energy)}</p>
          </div>
        </div>
      </div>
    );
  }
}
