import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Forum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      page: 0,
    };
  }

  componentDidMount() {
    this.fetchTopics(this.state.page);
  }

  fetchTopics = async (page) => {
    const url = '/api/forum/get-topics/' + page;
    const data = await fetch(url);
    if (data.status === 200) {
      const topics = await data.json();
      //console.log(topics);
      this.setState({
        topics: topics,
        page: page,
      });
    } else {
      console.log(data.statusText);
    }
  };

  getRang(rank) {
    if (rank === 0) return 'lambda';
    if (rank === 1) return 'mod';
    if (rank === 2) return 'admin';
  }

  loadTopicsNext = () => {
    if (this.state.topics.length >= 10) {
      const page_to_fetch = this.state.page + 1;
      this.fetchTopics(page_to_fetch);
    }
  };

  loadTopicsPrevious = () => {
    const page = this.state.page;
    if (page > 0) {
      this.fetchTopics(page - 1);
    }
  };

  render() {
    var self = this;
    return (
      <>
        <div id="arrow-title">
          {(function () {
            if (self.state.topics.length >= 1) {
              if (self.state.topics[0].hasMore) {
                return (
                  <button id="next-btn-forum" onClick={self.loadTopicsNext}>
                    <span role="img" aria-label="Next">
                      ➡️
                    </span>
                  </button>
                );
              }
            }
          })()}

          {this.state.page > 0 ? (
            <button id="previous-btn-forum" onClick={this.loadTopicsPrevious}>
              <span role="img" aria-label="Back">
                ⬅️
              </span>
            </button>
          ) : (
            <span></span>
          )}

          <div id="title-forum">
            <h1>
              <span role="img" aria-label="Emoji Crayon">
                ✏️
              </span>{' '}
              Share your ideas
            </h1>
          </div>
        </div>

        <p className="create-topic-link">
          <Link to="/forum/create">
            Create a Topic{' '}
            <span aria-label="Create a topic" role="img" className="emoji-create-topic">
              📝
            </span>
          </Link>
        </p>

        <div className="forum-component">
          <div className="line"></div>
          <table>
            <colgroup>
              <col width="250px" />
              <col />
              <col />
              <col width="150px" />
            </colgroup>

            <thead>
              <tr>
                <th>Title</th>
                <th>Messages</th>
                <th>Views</th>
                <th>Author</th>
                <th>Date</th>
              </tr>
            </thead>

            <tbody>
              {this.state.topics.map((element) => {
                const link = '/forum/topic/' + element._id;
                return (
                  <tr key={element._id} className={`importance-${element.important}`}>
                    <td className="topic-link">
                      <Link to={link}>{element.title}</Link>
                    </td>
                    <td>{element.answers.length}</td>
                    <td>{element.views}</td>
                    <td className="author">
                      <Link to={`/profil/view/${element.author_id}`} className={this.getRang(element.author_rang)}>
                        {element.author_username}
                      </Link>
                    </td>
                    <td className="date">{element.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {this.state.topics.length <= 0 ? <p className="no-topic-loaded">No Topic Yet</p> : <span></span>}
        </div>
      </>
    );
  }
}
