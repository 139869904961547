import React from 'react';
import { Component } from 'react';
import '../config';

export default class Buildings extends Component {
  constructor(props) {
    super(props);
    //Set default message
    this.state = {
      buildings: '',
      count: 0,
      nuclearColorState: 'blue',
      founderyColorState: 'blue',
      water_castleColorState: 'blue',
      oil_extractorColorState: 'blue',
      oil_platformColorState: 'blue',
      eolienneColorState: 'blue',
    };
  }

  componentDidMount() {
    this.fetchBuildings();
  }

  componentWillUnmount = () => {
    if (this.makeErrorDisappear) clearTimeout(this.makeErrorDisappear);
    if (this.decreaseCounter) clearTimeout(this.decreaseCounter);
  };

  fetchBuildings = async () => {
    const url = '/api/buildings/get';
    const data = await fetch(url);
    const buildings = await data.json();
    //console.log(buildings);

    const copy = JSON.parse(JSON.stringify(buildings.productions));
    this.setState({
      buildings: buildings,
      productions: copy,
    });
  };

  updateBuilding(building) {
    // Avoid Buttons Spm
    if (this.state.count === 0) {
      this.setState({
        count: 2,
      });
      this.decreaseCounter = setTimeout(() => {
        this.setState({
          count: 0,
        });
      }, 2000);

      fetch('/api/buildings/level_up', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ building: building }),
      }).then((res) => {
        if (res.status === 200) {
          let audio = new Audio(require('../../src/assets/sounds/building.mp3'));
          audio.volume = global.config.sound.volume;
          audio.play();
          console.log('Building updated!');
          this.fetchBuildings();
          // On peut réupdate directement après un update
          this.setState({
            count: 0,
          });
        } else {
          let audio = new Audio(require('../../src/assets/sounds/error.mp3'));
          audio.volume = global.config.sound.volume;
          audio.play();
          switch (res.statusText) {
            case 'nuclear':
              this.setState({
                nuclearColorState: 'red',
              });
              break;
            case 'foundery':
              this.setState({
                founderyColorState: 'red',
              });
              break;
            case 'oil_platform':
              this.setState({
                oil_platformColorState: 'red',
              });
              break;
            case 'oil_extractor':
              this.setState({
                oil_extractorColorState: 'red',
              });
              break;
            case 'eolienne':
              this.setState({
                eolienneColorState: 'red',
              });
              break;
            case 'water_castle':
              this.setState({
                water_castleColorState: 'red',
              });
              break;
            default:
              break;
          }
          this.makeErrorDisappear = setTimeout(() => {
            this.setState({
              nuclearColorState: 'blue',
              founderyColorState: 'blue',
              oil_platformColorState: 'blue',
              oil_extractorColorState: 'blue',
              eolienneColorState: 'blue',
              water_castleColorState: 'blue',
            });
          }, 2000);
        }
      });
    } else {
      console.log('Spam Alert');
    }
  }

  formatNumber(number) {
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      const new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  updateInputColorLeave = (event) => {
    event.preventDefault();
    const { name } = event.target; // const {value, name} = event.target;

    const toShow = JSON.parse(JSON.stringify(this.state.buildings.productions));
    this.setState({
      nuclearColorState: 'blue',
      founderyColorState: 'blue',
      oil_platformColorState: 'blue',
      oil_extractorColorState: 'blue',
      eolienneColorState: 'blue',
      water_castleColorState: 'blue',
      productions: toShow,
    });
  };

  // Updating input color when hovering button
  updateInputColorEnter = (event) => {
    event.preventDefault();
    const { name } = event.target; // const {value, name} = event.target;

    var news = this.state.productions;
    switch (name) {
      case 'nuclear':
        news.nuclear = this.state.buildings.future_productions.nuclear;
        this.setState({
          nuclearColorState: 'green',
          productions: news,
        });
        break;
      case 'foundery':
        news.foundery = this.state.buildings.future_productions.foundery;
        this.setState({
          founderyColorState: 'green',
          productions: news,
        });
        break;
      case 'oil_extractor':
        news.oil_extractor = this.state.buildings.future_productions.oil_extractor;
        this.setState({
          oil_extractorColorState: 'green',
          productions: news,
        });
        break;
      case 'oil_platform':
        news.oil_platform = this.state.buildings.future_productions.oil_platform;
        this.setState({
          oil_platformColorState: 'green',
          productions: news,
        });
        break;
      case 'eolienne':
        news.eolienne = this.state.buildings.future_productions.eolienne;
        this.setState({
          eolienneColorState: 'green',
          productions: news,
        });
        break;
      case 'water_castle':
        news.water_castle = this.state.buildings.future_productions.water_castle;
        this.setState({
          water_castleColorState: 'green',
          productions: news,
        });
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <>
        {this.state.buildings ? (
          <>
            <div id="title-buildings">
              <h1>
                <span role="img" aria-label="Emoji Buildings">
                  🏬
                </span>{' '}
                Buildings
              </h1>
            </div>

            <div className="buildings-component animate__animated animate__fadeIn">
              <div className="single-building-component oil_extractor">
                <h4>Oil Pump</h4>
                <p className="lvl"> [Level: {this.state.buildings.oil_extractor}]</p>
                <img src={require(`../assets/images/buildings/oil-pump.gif`)} alt="Oil Pump" />
                <p className="production">
                  <img
                    className="ressource-icon"
                    src={require(`../assets/images/ressources/oil-baril.png`)}
                    alt="Oil Icon"
                  />{' '}
                  {this.formatNumber(this.state.productions.oil_extractor)} / Hour
                </p>
                <button
                  name="oil_extractor"
                  className={this.state.oil_extractorColorState}
                  onClick={() => this.updateBuilding('oil_extractor')}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">UP</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/iron-bars.png`)}
                      alt="Iron Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.oil_extractor)}</p>
                  </span>
                  <span className="second-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/energy-thunder.png`)}
                      alt="Energy Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.oil_extractor / 4)}</p>
                  </span>
                </button>
              </div>

              <div className="single-building-component foundery">
                <h4>Foundery</h4>
                <p className="lvl"> [Level: {this.state.buildings.foundery}]</p>
                <img src={require(`../assets/images/buildings/iron-factory.png`)} alt="Foundery" />
                <p className="production">
                  <img
                    className="ressource-icon"
                    src={require(`../assets/images/ressources/iron-bars.png`)}
                    alt="Iron Icon"
                  />{' '}
                  {this.formatNumber(this.state.productions.foundery)} / Hour
                </p>
                <button
                  name="foundery"
                  className={this.state.founderyColorState}
                  onClick={() => this.updateBuilding('foundery')}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">UP</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/iron-bars.png`)}
                      alt="Iron Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.foundery)}</p>
                  </span>
                  <span className="second-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/energy-thunder.png`)}
                      alt="Energy Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.foundery / 4)}</p>
                  </span>
                </button>
              </div>

              <div className="single-building-component nuclear">
                <h4>Nuclear Plant</h4>
                <p className="lvl"> [Level: {this.state.buildings.nuclear}]</p>
                <img src={require(`../assets/images/buildings/nuclear-central.gif`)} alt="Nuclear Plant" />
                <p className="production">
                  <img
                    className="ressource-icon"
                    src={require(`../assets/images/ressources/energy-thunder.png`)}
                    alt="Energy Icon"
                  />{' '}
                  {this.formatNumber(this.state.productions.nuclear)} / Hour
                </p>
                <button
                  name="nuclear"
                  className={this.state.nuclearColorState}
                  onClick={() => this.updateBuilding('nuclear')}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  {' '}
                  <p className="up-text-btn">UP</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/iron-bars.png`)}
                      alt="Iron Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.nuclear)}</p>
                  </span>
                  <span className="second-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/energy-thunder.png`)}
                      alt="Energy Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.nuclear / 4)}</p>
                  </span>
                </button>
              </div>

              <div className="single-building-component oil_platform">
                <h4>Oil Rig</h4>
                <p className="lvl"> [Level: {this.state.buildings.oil_platform}]</p>
                <img src={require(`../assets/images/buildings/oil-platform.png`)} alt="Oil Rig" />
                <p className="production">
                  <img
                    className="ressource-icon"
                    src={require(`../assets/images/ressources/oil-baril.png`)}
                    alt="Oil Icon"
                  />{' '}
                  {this.formatNumber(this.state.productions.oil_platform)} / Hour
                </p>
                <button
                  name="oil_platform"
                  className={this.state.oil_platformColorState}
                  onClick={() => this.updateBuilding('oil_platform')}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">UP</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/iron-bars.png`)}
                      alt="Iron Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.oil_platform)}</p>
                  </span>
                  <span className="second-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/energy-thunder.png`)}
                      alt="Energy Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.oil_platform / 4)}</p>
                  </span>
                </button>
              </div>

              <div className="single-building-component eolienne">
                <h4>Wind Turbine</h4>
                <p className="lvl"> [Level: {this.state.buildings.eolienne}]</p>
                <img src={require(`../assets/images/buildings/wind-turbine.gif`)} alt="Wind Turbine" />
                <p className="production">
                  <img
                    className="ressource-icon"
                    src={require(`../assets/images/ressources/energy-thunder.png`)}
                    alt="Energy Icon"
                  />{' '}
                  {this.formatNumber(this.state.productions.eolienne)} / Hour
                </p>
                <button
                  name="eolienne"
                  className={this.state.eolienneColorState}
                  onClick={() => this.updateBuilding('eolienne')}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">UP</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/iron-bars.png`)}
                      alt="Iron Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.eolienne)}</p>
                  </span>
                  <span className="second-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/energy-thunder.png`)}
                      alt="Energy Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.eolienne / 4)}</p>
                  </span>
                </button>
              </div>

              <div className="single-building-component water_castle" id="chateau-eau">
                <h4>Water Tower</h4>
                <p className="lvl"> [Level: {this.state.buildings.water_castle}]</p>
                <img src={require(`../assets/images/buildings/water-tower.png`)} alt="Water Tower" />
                <p className="production">
                  <img
                    className="ressource-icon"
                    src={require(`../assets/images/ressources/water-drop.png`)}
                    alt="Water Icon"
                  />{' '}
                  {this.formatNumber(this.state.productions.water_castle)} / Hour
                </p>
                <button
                  name="water_castle"
                  className={this.state.water_castleColorState}
                  onClick={() => this.updateBuilding('water_castle')}
                  onMouseEnter={this.updateInputColorEnter}
                  onMouseLeave={this.updateInputColorLeave}
                >
                  <p className="up-text-btn">UP</p>
                  <span className="first-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/iron-bars.png`)}
                      alt="Iron Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.water_castle)}</p>
                  </span>
                  <span className="second-line">
                    <img
                      className="cost-icon"
                      src={require(`../assets/images/ressources/twentypixels/energy-thunder.png`)}
                      alt="Energy Icon"
                    />
                    <p>{this.formatNumber(this.state.buildings.prices.water_castle / 4)}</p>
                  </span>
                </button>
              </div>
            </div>
          </>
        ) : (
          <span></span>
        )}
      </>
    );
  }
}
