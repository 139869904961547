import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default function withModAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }
    componentDidMount() {
      fetch('/api/auth/check-mod-status')
        .then((res) => {
          if (res.status === 200) {
            this.setState({ loading: false });
          } else {
            const error = new Error(res.statusText);
            throw error;
          }
        })
        .catch((err) => {
          this.setState({ loading: false, redirect: true });
        });
    }
    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/chat" />;
      }
      return <ComponentToProtect {...this.props} />;
    }
  };
}
