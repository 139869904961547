import React from 'react';
import { Link } from 'react-router-dom';

function About() {
  return (
    <div className="about-pages-component">
      <h1>About</h1>
      <p className="about-text">
        We had the idea of Dokime a long time ago with my Brother. Together, we had created a first version of Dokime 7
        years ago from now. The game was visually quite tern and more complicated to realize than we thought. We finally
        abandoned this project by lack of time and investment. I decided to try again, motivated by learning the latest
        technologies. I would take a great pride in having a small amount of active players on Dokime. It’s in the
        memory of my Brother that I decided to realize this version 2.0 of Dokime, hoping that people will like it.
      </p>
      <br />
      <Link to="/" className="link-retour">
        Retour
      </Link>
    </div>
  );
}

export default About;
