import React, { Component } from 'react';

export default class SellHistoryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sell: this.props.sell,
    };
  }

  // Format displaying numbers
  formatNumber(number) {
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      const new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  getImage(name) {
    var toReturn;
    switch (name) {
      case 'iron':
        toReturn = <img src={require('../../src/assets/images/market/items/iron-cart.png')} alt="Iron Cart" />;
        break;
      case 'oil':
        toReturn = <img src={require('../../src/assets/images/market/items/oil-baril.png')} alt="Oil Baril" />;
        break;
      case 'water':
        toReturn = <img src={require('../../src/assets/images/market/items/water-bottle.png')} alt="Water Bottle" />;
        break;
      case 'energy':
        toReturn = <img src={require('../../src/assets/images/market/items/energy-bottle.png')} alt="Energy Bottle" />;
        break;

      default:
        break;
    }
    return toReturn;
  }

  getWeapon(weapon) {
    var toReturn;
    switch (weapon) {
      case 'knife':
        toReturn = <img src={require('../../src/assets/images/market/items/knife.png')} alt="Knife" />;
        break;
      case 'gun':
        toReturn = <img src={require('../../src/assets/images/market/items/gun.png')} alt="Gun" />;
        break;
      case 'rifle':
        toReturn = <img src={require('../../src/assets/images/market/items/rifle.png')} alt="Rifle" />;
        break;
      case 'sniper':
        toReturn = <img src={require('../../src/assets/images/market/items/sniper.png')} alt="Sniper" />;
        break;
      case 'rpg':
        toReturn = <img src={require('../../src/assets/images/market/items/rpg.png')} alt="RPG" />;
        break;

      default:
        break;
    }
    return toReturn;
  }

  getMessage(sell) {
    var toReturn;
    switch (sell.type) {
      case 'bank-trade':
        if (sell.name === 'water')
          toReturn =
            <p>
              You bought {this.formatNumber(sell.quantity)}{' '}
              <img src={require('../../src/assets/images/market/items/water-bottle.png')} alt="Water Bottle" /> for{' '}
              {this.formatNumber(sell.price)}{' '}
              <img
                className="gold-img"
                src={require('../../src/assets/images/market/gold-coins.png')}
                alt="Gold Coins"
              />
            </p>
        else if (sell.name === 'gold')
          toReturn =
            <p>
              You sold {this.formatNumber(sell.price)}
              <img src={require('../../src/assets/images/market/items/water-bottle.png')} alt="Water Bottle" /> for{' '}
              {this.formatNumber(sell.quantity)}{' '}
              <img
                className="gold-img"
                src={require('../../src/assets/images/market/gold-coins.png')}
                alt="Gold Coins"
              />
            </p>
        break;
      case 'ressource':
        toReturn =
          <p>
            You sold {this.formatNumber(sell.quantity)} {this.getImage(sell.name)} for{' '}
            {this.formatNumber(sell.price)}{' '}
            <img className="gold-img" src={require('../../src/assets/images/market/gold-coins.png')} alt="Gold Coins" />
          </p>
        break;
      case 'weapon':
        toReturn =
          <p>
            You sold {this.formatNumber(sell.quantity)} of {this.getWeapon(sell.name)} for{' '}
            {this.formatNumber(sell.price)}{' '}
            <img className="gold-img" src={require('../../src/assets/images/market/gold-coins.png')} alt="Gold Coins" />
          </p>
        break;
      case 'land':
        toReturn =
          <p>
            You sold{' '}
            <img className="country-img" src={require('../../src/assets/images/market/items/country.png')} alt="Country" />
            {sell.name} for {this.formatNumber(sell.price)}{' '}
            <img className="gold-img" src={require('../../src/assets/images/market/gold-coins.png')} alt="Gold Coins" />
          </p>
        break;

      default:
        break;
    }
    return toReturn;
  }

  render() {
    return (
      <div id="sell-history-item">
        {this.getMessage(this.state.sell)}
        <span className="date">{this.state.sell.date}</span>
      </div>
    );
  }
}
