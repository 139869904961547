import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

export default class Market extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  componentDidMount() {
    const self = this;
    this.fetchLandsGeoData();
    window.loadOwnerProfil = function (owner) {
      if (owner !== '0') {
        self.props.history.push('/profil/view/' + self.reformatLink(owner));
      } else console.log('Bank is the owner');
    };
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  buyCountry(country, price, confirm) {
    if (confirm) {
      // Si on load après confirmation
      fetch('/api/lands/submit-buy', {
        method: 'POST',
        body: JSON.stringify({
          country: this.state.country,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              buy_in_process: 'Complete',
              price: '',
              success: 'land-bought',
            });
            this.chart.dispose();
            this.fetchLandsGeoData();
          } else if (res.statusText) {
            this.setState({
              buy_in_process: '',
              error: res.statusText,
              country: country,
              price: price,
              success: '',
            });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // Si on load sans confirmation
      this.setState({
        buy_in_process: true,
        country: country,
        price: price,
        success: '',
      });
    }
  }

  cancelBuy() {
    this.setState({
      buy_in_process: '',
      error: '',
      country: '',
      price: '',
      owner_id: '',
      success: '',
    });
  }

  fetchLandsGeoData = async () => {
    const data = await fetch('/api/lands/get');
    if (data.status === 200) {
      var lands = await data.json();
      //console.log(lands);
      this.setState({
        lands: lands,
      });
      this.initializeMap();
    } else {
      console.log(data.statusText);
    }
  };

  initializeMap() {
    const self = this;
    let map = am4core.create('chartdiv', am4maps.MapChart);
    am4core.unuseAllThemes();
    am4core.useTheme(am4themes_animated);
    map.geodata = am4geodata_worldLow;
    let polygonSeries = new am4maps.MapPolygonSeries();
    polygonSeries.useGeodata = true;
    polygonSeries.exclude = ['ES-TF'];
    polygonSeries.include = [
      'PT',
      'ES',
      'FR',
      'DE',
      'IT',
      'BE',
      'AT',
      'GB',
      'IE',
      'CH',
      'LU',
      'PL',
      'SK',
      'CZ',
      'HR',
      'SI',
      'BG',
      'RO',
      'UA',
      'BY',
      'MD',
      'HU',
      'RS',
      'GR',
      'BA',
      'AL',
      'ME',
      'MK',
      'XK',
      'DK',
      'NO',
      'SE',
      'FI',
      'EE',
      'LV',
      'LT',
      'NL',
      'DK',
    ];

    const index = self.state.lands.length - 1;
    const viewer = self.state.lands[index].viewer_id;
    Object.values(this.state.lands).forEach((element) => {
      if (element.owner_id === viewer && element.for_sell !== true) {
        element.fill = '#5ea2b5';
      }
      polygonSeries.data.push(element);
    });

    // customing template
    let polygonTemplate = polygonSeries.mapPolygons.template;
    // polygonTemplate.tooltipHTML = `<div class='tooltip-content'><p class='country'>{name}</p><br>Bonus: {bonus}<br>Price: {price}<br>Owner: <a id='land-owner-btn' onClick='loadOwnerProfil("{owner_id}")'>{owner}</a></div>`;
    polygonSeries.tooltip.label.interactionsEnabled = true;
    polygonSeries.tooltip.keepTargetHover = true;
    polygonSeries.calculateVisualCenter = true;
    polygonSeries.tooltip.getFillFromObject = false;
    polygonSeries.tooltip.background.fill = am4core.color('#ffffff');
    polygonSeries.mapPolygons.template.tooltipPosition = 'fixed';
    polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    polygonTemplate.events.on('hit', function (ev) {
      // ev.target.series.chart.zoomToMapObject(ev.target); // zoom to an object
      const index = self.state.lands.length - 1;
      // Pour remettre en noir le message après un achat par exemple…
      self.setState({
        error: '',
        success: '',
      });
      if (ev.target.dataItem.dataContext.for_sell) {
        if (ev.target.dataItem.dataContext.owner_id !== self.state.lands[index].viewer_id) {
          self.setState({
            owner_id: ev.target.dataItem.dataContext.owner_id,
            owner: ev.target.dataItem.dataContext.owner,
          });
          self.buyCountry(ev.target.dataItem.dataContext.name, ev.target.dataItem.dataContext.price);
        } else {
          self.setState({
            country: ev.target.dataItem.dataContext.name,
            buy_in_process: 'Cannot-Buy-Own-Land',
          });
        }
      } else {
        if (ev.target.dataItem.dataContext.owner_id !== self.state.lands[index].viewer_id) {
          self.setState({
            buy_in_process: 'NFS',
            country: ev.target.dataItem.dataContext.name,
            owner: ev.target.dataItem.dataContext.owner,
            owner_id: ev.target.dataItem.dataContext.owner_id, // jcrois inutile
          });
        } else {
          self.setState({
            country: ev.target.dataItem.dataContext.name,
            buy_in_process: 'Sell-Own-Country',
          });
        }
      }
    });

    polygonTemplate.events.on('over', over);
    function over(ev) {
      // create your custom tooltip depending on dataContext…
      // if for sell on affiche le price, sinon nan
      if (ev.target.dataItem.dataContext.for_sell) {
        ev.target.tooltipHTML = `<div class='tooltip-content'><p class='country'>{name}</p><br>Bonus: +{bonus}%<br>Price: {price}<br>Owner: <a class="{owner}" id='land-owner-btn' onClick='loadOwnerProfil("{owner_id}")'>{owner}</a></div>`;
      } else {
        ev.target.tooltipHTML = `<div class='tooltip-content'><p class='country'>{name}</p><br>Bonus: +{bonus}%<br>Price: NFS<br>Owner: <a id='land-owner-btn' onClick='loadOwnerProfil("{owner_id}")'>{owner}</a></div>`;
      }
    }

    map.series.push(polygonSeries);

    map.minZoomLevel = 1.15;
    map.maxZoomLevel = 1.15;
    map.seriesContainer.draggable = false;
    map.seriesContainer.resizable = false;
    map.homeZoomLevel = 1.15;
    map.homeGeoPoint = {
      latitude: 53,
      longitude: 15,
    };

    map.events.on('ready', function (ev) {
      self.state.lands.forEach((country) => {
        polygonSeries.getPolygonById(country.id).fill = am4core.color(country.fill);
      });
    });

    this.chart = map;
  }

  reformatLink(user_id) {
    //console.log('user_id=', user_id);
    const clean_user_id = user_id.replace(',', '');
    return clean_user_id;
  }

  // Format displaying numbers
  formatNumber(number) {
    number = number + '';
    number = number
      .split('')
      .reverse()
      .join('')
      .replace(/([0-9]{3})/g, '$1,')
      .split('')
      .reverse()
      .join('');
    if (number.startsWith(',')) {
      const new_number = number.substring(1);
      return new_number;
    } else {
      return number;
    }
  }

  render() {
    const self = this;
    return (
      <>
        <div id="title-lands">
          <h1>
            <span role="img" aria-label="Emoji Lands">
              🗺
            </span>{' '}
            Lands
          </h1>
        </div>
        <div className="map-container animate__animated animate__fadeIn">
          <div id="chartdiv" style={{ width: '100%', height: '500px' }}></div>
          <div id="action-div" className={this.state.success}>
            {(function () {
              if (
                self.state.buy_in_process &&
                self.state.buy_in_process !== 'Complete' &&
                self.state.buy_in_process !== 'NFS' &&
                self.state.buy_in_process !== 'Cannot-Buy-Own-Land' &&
                self.state.buy_in_process !== 'Sell-Own-Country' &&
                self.state.error !== 'No-Money'
              ) {
                return (
                  <p>
                    {self.state.owner_id !== 0 ? (
                      <>
                        Buy <span className="country">{self.state.country}</span> from{' '}
                        <Link className="user-link" to={`profil/view/${self.state.owner_id}`}>
                          {self.state.owner}
                        </Link>{' '}
                        for <span className="price">{self.formatNumber(self.state.price)}</span>{' '}
                      </>
                    ) : (
                      <>
                        Buy <span className="country">{self.state.country}</span> from the{' '}
                        <span className="Bank">Bank</span> for{' '}
                        <span className="price">{self.formatNumber(self.state.price)}</span>{' '}
                      </>
                    )}
                    <button
                      className="confirm-btn"
                      onClick={() => self.buyCountry(self.state.country, self.state.price, true)}
                    >
                      CONFIRM
                    </button>{' '}
                    <button className="cancel-btn" onClick={() => self.cancelBuy()}>
                      CANCEL
                    </button>
                  </p>
                );
              } else if (self.state.error === 'No-Money') {
                return (
                  <p>
                    You don't have enough <span className="price">money</span> to buy{' '}
                    <span className="country">{self.state.country}</span>
                  </p>
                );
              } else if (self.state.error !== '') {
                return (
                  <p>
                    {self.state.error}{' '}
                    <button className="cancel-btn" onClick={() => self.cancelBuy()}>
                      OK
                    </button>
                  </p>
                );
              } else if (!self.state.buy_in_process) {
                return (
                  <p>
                    Click a <span className="country">Land</span> to <span className="price">Buy It</span>
                  </p>
                );
              } else if (self.state.buy_in_process === 'Complete') {
                return <p>You bought {self.state.country} !</p>;
              } else if (self.state.buy_in_process === 'NFS') {
                return (
                  <p>
                    <Link className="user-link" to={`profil/view/${self.state.owner_id}`}>
                      {self.state.owner}
                    </Link>{' '}owns{' '}
                    <span className="country">{self.state.country}</span>{' '}and does not sell it.
                  </p>
                );
              } else if (self.state.buy_in_process === 'Cannot-Buy-Own-Land') {
                return (
                  <p>
                    You own{' '}<span className="country">{self.state.country}</span>. Update its price on the{' '}
                    <Link to="/market">Market</Link>
                  </p>
                );
              } else if (self.state.buy_in_process === 'Sell-Own-Country') {
                return (
                  <p>
                    You own{' '}<span className="country">{self.state.country}</span>. Sell it on the{' '}
                    <Link to="/market">Market</Link>
                  </p>
                );
              }
            })()}
          </div>
        </div>
      </>
    );
  }
}
